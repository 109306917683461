<template>
  <v-row>
    <v-col class="d-flex justify-end mb-xs-4">
      <v-btn
        class="mr-5"
        elevation="2"
        outlined
        :loading="loadValidatedDoc"
        @click="downloadValidatedDoc"
      >
        <v-icon
          left
          dark
        >
          mdi-arrow-collapse-down
        </v-icon>
        Validated Documents
      </v-btn>
      <v-dialog
        v-model="clearDataDialogVal"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="error"
            elevation="2"
            :disabled="String(updateRes.Uploaded) == 0 || fileUploadLoading == true ? true : false"
            outlined
            v-bind="attrs"
            v-on="on"
          >
            Clear All
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card v-if="!loadClearApi && clearDataRes == undefined">
            <v-toolbar
              color="error"
              dark
            >
              Attention!
            </v-toolbar>
            <v-card-text>
              <div class="text-h5">
                Are you sure you want to delete all of the processed files?
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                color="error"
                elevation="2"
                outlined
                @click="deleteDocs"
              >
                Yes!
              </v-btn>
              <v-btn
                text
                @click="dialog.value = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-else-if="clearDataRes">
            <v-card-text>
              <div class="d-flex justify-center">
                <v-icon
                  x-large
                  color="green darken-2"
                >
                  mdi-thumb-up
                </v-icon>
              </div>
            </v-card-text>
          </v-card>
          <v-card v-else-if="clearDataRes == false">
            <v-card-text>
              <div class="d-flex justify-center align-center">
                <div class="error--text text-darken-1 text-body-1 mr-4">
                  Oops... Something went wrong!
                </div>
                <v-icon
                  x-large
                  color="error darken-2"
                >
                  mdi-thumb-down
                </v-icon>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            v-else-if="loadClearApi"
            color="primary"
            dark
          >
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ClearData',

    model: {
      prop: 'clearDataDialog',
      event: 'update',
    },

    props: {
      loadClearApi: {
        type: Boolean,
        default () {},
      },
      clearDataRes: {
        type: Boolean,
        default () {},
      },
      clearDataDialog: {
        type: Boolean,
        default () {},
      },
      loadValidatedDoc: {
        type: Boolean,
        default () {},
      },
      updateRes: {
        type: Object,
        default () {},
      },
      fileUploadLoading: {
        type: Boolean,
        default () {},
      },
    },

    computed: {
      clearDataDialogVal: {
        get () {
          return this.clearDataDialog
        },
        set (clearDataDialog) {
          this.$emit('update', clearDataDialog)
        },
      },
    },

    methods: {
      deleteDocs () {
        this.$emit('deleteDocs')
      },
      downloadValidatedDoc () {
        this.$emit('downloadValidatedDoc')
      },
    },
  }
</script>
