<template>
  <div>
    <v-card
      id="uploadDocCard"
      class="mx-auto"
      shaped
      max-width="800"
      style="background:white;"
    >
      <vue-dropzone
        id="dropzone"
        ref="myVueDropzone"
        :use-custom-slot="true"
        :options="dropzoneOptions"
        @vdropzone-sending-multiple="uploadFile"
      >
        <div class="file-selector mt-n8 mb-n10">
          <figure class="mt-n8 mb-n4">
            <svg
              width="104px"
              height="104px"
              viewBox="0 0 104 104"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <defs>
                <circle
                  id="path-1"
                  cx="36"
                  cy="36"
                  r="36"
                />
                <filter
                  id="filter-2"
                  x="-37.5%"
                  y="-29.2%"
                  width="175.0%"
                  height="175.0%"
                  filterUnits="objectBoundingBox"
                >
                  <feOffset
                    dx="0"
                    dy="6"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                  />
                  <feGaussianBlur
                    stdDeviation="8"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                  />
                  <feColorMatrix
                    values="0 0 0 0 0.0117647059   0 0 0 0 0.0862745098   0 0 0 0 0.160784314  0 0 0 0.08 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                  />
                  <feOffset
                    dx="0"
                    dy="1"
                    in="SourceAlpha"
                    result="shadowOffsetOuter2"
                  />
                  <feGaussianBlur
                    stdDeviation="1"
                    in="shadowOffsetOuter2"
                    result="shadowBlurOuter2"
                  />
                  <feColorMatrix
                    values="0 0 0 0 0.0117647059   0 0 0 0 0.0862745098   0 0 0 0 0.160784314  0 0 0 0.11 0"
                    type="matrix"
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"
                  />
                  <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="shadowMatrixOuter2" />
                  </feMerge>
                </filter>
              </defs>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Artboard"
                  transform="translate(-460.000000, -125.000000)"
                >
                  <g
                    id="Group-4"
                    transform="translate(412.000000, 129.000000)"
                  >
                    <g
                      id="Group-2"
                      transform="translate(58.000000, 0.000000)"
                    >
                      <circle
                        id="Oval"
                        fill="#3560FF"
                        opacity="0.100000001"
                        cx="42"
                        cy="42"
                        r="42"
                      />
                      <g
                        id="Group"
                        transform="translate(6.000000, 6.000000)"
                      >
                        <g id="Oval">
                          <use
                            fill="black"
                            fill-opacity="1"
                            filter="url(#filter-2)"
                            xlink:href="#path-1"
                          />
                          <use
                            fill="#FFFFFF"
                            fill-rule="evenodd"
                            xlink:href="#path-1"
                          />
                        </g>
                        <g
                          id="upload-cloud"
                          transform="translate(21.818182, 24.000000)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        >
                          <polyline
                            id="Path"
                            stroke="#000000"
                            points="19.6458087 17.3789847 14.3565525 12.0897285 9.06729634 17.3789847"
                          />
                          <path
                            id="Path"
                            d="M14.3565525,12.0897285 L14.3565525,24.1794569"
                            stroke="#3560FF"
                          />
                          <path
                            id="Path"
                            d="M25.6438239,20.7792208 C28.2965835,19.3021499 29.6312816,16.1761528 28.8860265,13.1856562 C28.1407715,10.1951596 25.5052337,8.10125672 22.4838689,8.09921935 L20.8179512,8.09921935 C19.7219904,3.76967373 16.1275086,0.577339516 11.7773112,0.0700384831 C7.42711383,-0.43726255 3.22057026,1.84535014 1.19724759,5.81113853 C-0.826075091,9.77692693 -0.247870665,14.6059952 2.6515151,17.9569414"
                            stroke="#3560FF"
                          />
                          <polyline
                            id="Path"
                            stroke="#3560FF"
                            points="19.6458087 17.3789847 14.3565525 12.0897285 9.06729634 17.3789847"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </figure>
          Add Or Drop New Document (PDF) Here
        </div>
        <span
          v-if="progressBar && uploadProgressbar < 100"
          style="color:grey; background:white;"
          class="float-left mb-1"
        >
          Uploading {{ fileLength }} file(s)
        </span>
        <span
          v-if="progressBar && uploadProgressbar == 100"
          style="color:grey; background:white;"
          class="float-left mb-1"
        >
          Uploaded {{ fileLength }} file(s)
        </span>
        <span
          v-if="progressBar"
          style="color:grey; background:white;"
          class="font-weight-light float-right mb-1"
        >
          {{ fileCounter }} / {{ fileLength }} file(s)
        </span>
        <v-progress-linear
          v-if="progressBar"
          height="15"
          :value="uploadProgressbar"
          color="teal"
          rounded
          striped
        >
          <strong>{{ uploadProgressbar }}% uploaded</strong>
        </v-progress-linear>
        <!-- <div>
          <ul v-if="tempAttachments.length > 0">
            <li
              v-for="tempAttachment in tempAttachments"
              :key="tempAttachment._id"
            >
              <div class="file-details">
                <div class="file-name display-flex align-center">
                  <p
                    ref="attachmentTitle"
                    class=""
                  >
                    {{ tempAttachment.title }}
                  </p>
                </div>
                <div class="file-info">
                  <span class="uploaded-date"> {{ tempAttachment.size }} bytes </span>
                  <span
                    v-if="checkProgress(tempAttachment)"
                    class="upload-prgress"
                  >
                    {{ `${tempAttachment.progress} %` }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div> -->
      </vue-dropzone>
    </v-card>
    <!-- <v-card
      id="uploadDocCard"
      elevation="5"
      shaped
    >
      <v-card-title>
        Upload New Document (PDF)
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-model="fileUploaderModelVal"
          accept=".pdf"
          placeholder="Upload new document here"
          counter
          multiple
          :clearable="false"
          truncate-length="16"
          prepend-icon="mdi-file-pdf"
          @change="uploadFile"
        >
          <template
            v-if="fileUploaderModel"
            v-slot:[`counter`]
          >
            <span
              style="color:grey"
              class="text-caption font-weight-light"
            >
              {{ fileCounter }} / {{ fileLength }} files
            </span>
          </template>
          <template
            v-if="uploadProgressbar != 100"
            v-slot:selection="{ index }"
          >
            <span v-if="index === 0">
              Uploading {{ fileLength }} file(s)
            </span>
          </template>
          <template
            v-else-if="uploadProgressbar == 100"
            v-slot:selection="{ index }"
          >
            <span v-if="index === 0">
              Uploaded {{ fileLength }} file(s)
            </span>
          </template>
        </v-file-input>
        <v-progress-linear
          v-if="fileUploaderModel"
          height="15"
          :value="uploadProgressbar"
          color="teal"
          rounded
          striped
        >
          <strong>{{ uploadProgressbar }}% uploaded</strong>
        </v-progress-linear>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>

  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  // import Vue from 'vue'

  export default {
    name: 'UploadDoc',

    components: {

      vueDropzone: vue2Dropzone,
    },

    model: {
      prop: 'fileUploaderModel',
      event: 'update',
    },

    props: {
      fileUploaderModel: {
        type: Array,
        default () {},
      },
      fileCounter: {
        type: Number,
        default () {},
      },
      uploadProgressbar: {
        type: Number,
        default () {},
      },
      progressBar: {
        type: Boolean,
        default () {},
      },
    },

    data () {
      return {
        fileLength: null,

        // msg: 'Welcome to Your Vue.js App',
        // tempAttachments: [],
        // attachments: [],
        dropzoneOptions: {
          // The Url Where Dropped or Selected files will be sent
          // url: files => {
          //   this.uploadFile(files)
          // },
          url: 'http://localhost:8080',
          // File Size allowed in MB
          maxFilesize: 102400000,
          // Authentication Headers like Access_Token of your application
          // headers: {
          //   Authorization: 'Access Token',
          // },
          // The way you want to receive the files in the server
          // paramName: function (n) {
          //   return 'file[]'
          // },
          dictDefaultMessage: 'Upload Files Here xD',
          includeStyling: false,
          previewsContainer: false,
          thumbnailWidth: 250,
          thumbnailHeight: 140,
          uploadMultiple: true,
          parallelUploads: 1000,
          acceptedFiles: '.pdf',
        },
      }
    },

    computed: {
      fileUploaderModelVal: {
        get () {
          return this.fileUploaderModel
        },
        set (fileUploaderModel) {
          this.$emit('update', fileUploaderModel)
        },
      },
      // getTempAttachments () {
      //   return this.tempAttachments
      // },
      // getAttachments () {
      //   return this.attachments
      // },
    },

    methods: {
      uploadFile (files) {
        if (files !== null) {
          if (this.progressBar && this.uploadProgressbar !== 100) {
            this.fileLength = this.fileLength + files.length
          } else if (this.progressBar && this.uploadProgressbar === 100) {
            this.fileLength = files.length
          } else {
            this.fileLength = files.length
          }

          this.$emit('uploadFile', files)
        }
      },

      // checkProgress (attachment) {
      //   return attachment.progress !== null
      // },

      // fileAdded (file) {
      //   // console.log('File Dropped => ', file)
      //   // Construct your file object to render in the UI
      //   const attachment = {}
      //   attachment._id = file.upload.uuid
      //   attachment.title = file.name
      //   attachment.type = 'file'
      //   attachment.extension = '.' + file.type.split('/')[1]
      //   attachment.user = JSON.parse(localStorage.getItem('user'))
      //   attachment.content = 'File Upload by Select or Drop'
      //   attachment.thumb = file.dataURL
      //   attachment.thumb_list = file.dataURL
      //   attachment.isLoading = true
      //   attachment.progress = null
      //   attachment.size = file.size
      //   this.tempAttachments = [...this.tempAttachments, attachment]
      // },

      // sendingFiles (files, xhr, formData) {
      //   // const sub = Vue.$keycloak.idTokenParsed.sub
      //   // formData.append('file', files)
      //   // formData.append('clientID', process.env.VUE_APP_CLIENT_ID)
      //   // formData.append('userID', sub)
      //   // console.log('if you want to change the upload time or add data to the formData you can do it here.')
      //   // console.log('Files sending', files)
      // },

      // uploadProgress (file, progress, bytesSent) {
      //   // console.log('File Upload Progress', progress)
      //   this.tempAttachments.map(attachment => {
      //     if (attachment.title === file.name) {
      //       attachment.progress = `${Math.floor(progress)}`
      //     }
      //   })
      // },

      // success (file, response) {
      //   // console.log('File uploaded successfully')
      //   // console.log('Response is ->', response)
      // },
    },

  }
</script>

<style scoped>
.file-selector {
  padding: 55px;
  font-weight: 600;
  background-color: #ffffff;
  color: #4e5b69;
  z-index: -9;
}
figure {
  margin: 0px;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  border: 1px dashed rgb(255, 255, 255);
  border-radius: 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.separator:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 200px;
  background: #d8d8d8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
span {
  position: relative;
  background: #f9f9f9;
  padding: 0 4px;
  z-index: 9;
  font-size: 12px;
  color: #ffffff;
}

ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
}
li {
  border: 1px solid;
  margin-bottom: 8px;
  border-radius: 8px;
  margin-right: 8px;
  padding: 0 10px;
}
.file-name {
  min-width: 150px;
  font-size: 13px;
}
.uploaded-date {
  font-size: 12px;
}
.upload-progress {
  font-size: 12px;
}
.file-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

</style>
