// TODO: Fix error handling, currently only being console logged and not returned
import axios from 'axios'
import { error } from 'pdf-lib'
import Vue from 'vue'
// import updateStats from '@/api-php/updateStats.php'

export default {
    name: 'api',

    getCities () {
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_CONFIGURATION_API + 'Location/GetCities')
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    console.log(error)
                })
            case 'production':
                return axios.get('./api-php/getCities.php', {
                params: {
                        API: process.env.VUE_APP_CONFIGURATION_API_PROD,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                    },
                })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    console.log(error)
                })
            default:
                return undefined
        }
    },

    fetchUpdate () {
        /**
         * call API to fetch update for stats cards
         * @returns update for the stats cards
         */

        // for development use axios, otherwise use php to call APIs
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_IDENTIFICATION_API + 'stats/counts?clientID=' + process.env.VUE_APP_CLIENT_ID)
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    console.log(error)
                })
            case 'production':
                return axios.get('./api-php/updateStats.php', {
                params: {
                    API: process.env.VUE_APP_IDENTIFICATION_API_PROD,
                    clientID: process.env.VUE_APP_CLIENT_ID,
                    },
                })
                .then(response => {
                    // TODO: handle for empty response
                    console.log(response.data)
                    return response.data
                })
                .catch(error => {
                    console.log(error)
                })
            default:
                return undefined
        }
    },

    allDocs () {
        /**
         * call API to fetch all extracted documents
         * @returns data of all docs, filled in the table
         */

        // for development use axios, otherwise use php to call APIs
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_EXTRACTION_API + 'document/all?clientID=' + process.env.VUE_APP_CLIENT_ID + '&includeFields=true')
                .then(response => {
                    // TODO: handle for empty response
                    let id = 1
                    const cleanedResponse = []
                    response.data.map(result => {
                        // guid is stored in an <a> tag, needs to be extracted
                        const parser = new DOMParser()
                        const guidHTML = parser.parseFromString(result.guid, 'text/html')
                        const guid = guidHTML.body.querySelector('a').innerText
                        let cityString = ''
                        const city = result.fields ? result.fields.find(d => d.key === 'Betriebsinformation_Ortsnetz:') : null
                        if (city) {
                            cityString = city.editedValue ? city.editedValue : city.value
                        }

                        cleanedResponse.push({
                            id,
                            time: result.time,
                            guid: guid,
                            fileName: result.fileName,
                            barcode: result.barcode,
                            validated: (result.documentValidated == null ? 'No' : result.documentValidated),
                            exported: result.documentExported,
                            documentClass: result.type,
                            detectedLanguage: result.language,
                            city: cityString,
                        })
                        id += 1
                        // cleanedResponse.validated = result.documentValidated
                        // cleanedResponse.exported = result.documentExported
                    })
                    return cleanedResponse
                })
                .catch(error => {
                    // return error
                    console.log(error)
                })
            case 'production':
                return axios.get('./api-php/allDocs.php', {
                    params: {
                        API: process.env.VUE_APP_EXTRACTION_API_PROD,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                        },
                    })
                    .then(response => {
                        // TODO: handle for empty response
                        let id = 1
                        const cleanedResponse = []
                        response.data.map(result => {
                            // guid is stored in an <a> tag, needs to be extracted
                            const parser = new DOMParser()
                            const guid = result.guid
                            let cityString = ''
                            const city = result.fields ? result.fields.find(d => d.key === 'Betriebsinformation_Ortsnetz:') : null
                            if (city) {
                                cityString = city.editedValue ? city.editedValue : city.value
                            }

                            cleanedResponse.push({
                                id,
                                time: result.time,
                                guid: guid,
                                fileName: result.fileName,
                                barcode: result.barcode,
                                validated: (result.documentValidated == null ? 'No' : result.documentValidated),
                                exported: result.documentExported,
                                documentClass: result.type,
                                detectedLanguage: result.language,
                                city: cityString,
                            })
                            id += 1
                            // cleanedResponse.validated = result.documentValidated
                            // cleanedResponse.exported = result.documentExported
                        })
                        return cleanedResponse
                    })
                    .catch(error => {
                        console.log(error)
                    })
            default:
                return undefined
            }
    },

    getfailedDocs () {
        /**
         * call API to fetch all failed documents
         * @returns data of all failed docs, filled in the table
         */
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_IDENTIFICATION_API + 'stats/failed?clientID=' + process.env.VUE_APP_CLIENT_ID)
                .then(response => {
                    const cleanedResponse = []
                    response.data.map(result => {
                        const parser = new DOMParser()
                        const guidHTML = parser.parseFromString(result.guid, 'text/html')
                        const guid = guidHTML.body.querySelector('a').innerText

                        delete result.guid
                        result.guid = guid

                        cleanedResponse.push(result)
                    })
                    return cleanedResponse
                })
                .catch(err => {
                    console.log(err.message)
                })
            case 'production':
                return axios.get('./api-php/failedDocs.php', {
                    params: {
                        API: process.env.VUE_APP_IDENTIFICATION_API_PROD,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                    },
                })
                .then(response => {
                    const cleanedResponse = []
                    response.data.map(result => {
                        const parser = new DOMParser()
                        const guidHTML = parser.parseFromString(result.guid, 'text/html')
                        const guid = guidHTML.body.querySelector('a').innerText

                        delete result.guid
                        result.guid = guid

                        cleanedResponse.push(result)
                    })
                    return cleanedResponse
                })
                .catch(err => {
                    console.log(err)
                })
            default:
                break
        }
    },

    uploadFile (currFile, selectedCity) {
        /**
         * call upload API
         * @param currFile file which is uploaded by user
         * @retuns uploads file on server
         */

        if (Vue.$keycloak.idTokenParsed === undefined) {
            console.log('Keycloak did not provide user ID token!')
            return 'Keycloak did not provide user ID token!'
        }
        const sub = Vue.$keycloak.idTokenParsed.sub
        const formData = new FormData()
        switch (process.env.NODE_ENV) {
            case 'development':
                formData.append('file', currFile) // currFile.name
                formData.append('clientID', process.env.VUE_APP_CLIENT_ID)
                formData.append('userID', sub)
                formData.append('selectedCityID', selectedCity.gemeinde)
                formData.append('env_deploy', 'stage')

                return axios.post(process.env.VUE_APP_UPLOADER_API, formData)
                .then(response => {
                    // TODO: handle for errors
                    return response.data
                })
                .catch(error => {
                    console.log(error)
                })

            case 'production':
                formData.append('file', currFile) // currFile.name
                formData.append('API', process.env.VUE_APP_UPLOADER_API_PROD + '/')
                formData.append('clientID', process.env.VUE_APP_CLIENT_ID)
                formData.append('userID', sub)
                formData.append('selectedCityID', selectedCity.gemeinde)
                formData.append('env_deploy', 'prod')

                return axios.post('./api-php/uploadFile.php', formData)
                .then(response => {
                    // TODO: handle for errors
                    console.log(response)
                    return response.data
                })
                .catch(error => {
                    console.log(error)
                })
            default:
                return undefined
        }
    },

    getDocId (guid) {
        /**
         * call identification API
         * @param guid GUID of uploaded file
         * @returns specific document identification using GUID
         */
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_IDENTIFICATION_API + 'documents/' + guid + '?clientID=' + process.env.VUE_APP_CLIENT_ID)
                .then(response => {
                    // TODO: handle for empty response
                    const abbyPDFURL = response.data.pdf
                    let localURL = ''
                    if (abbyPDFURL) {
                        // replacing abby url with the deepreader server one one
                        localURL = abbyPDFURL.replace('http://abbyy-ocr-api', 'http://dev.deepreader.de:8093')
                    }
                    return localURL
                })
                .catch(error => {
                    // return error
                    console.log(error)
                })
            case 'production':
                return axios.get('./api-php/getDocIdent.php', {
                    params: {
                        API: process.env.VUE_APP_IDENTIFICATION_API_PROD,
                        GUID: guid,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                        },
                    })
                .then(response => {
                    // TODO: handle for empty response
                    const abbyPDFURL = response.data.pdf
                    if (abbyPDFURL) {
                        // replacing abby url with the deepreader server one one
                        // abbyPDFURL = new URL(abbyPDFURL)

                        // const formData = new FormData()
                        // formData.append('clientID', process.env.VUE_APP_CLIENT_ID)
                        // formData.append('userID', Vue.$keycloak.idTokenParsed.sub)
                        // formData.append('API', process.env.VUE_APP_IDENTIFICATION_API)
                        // formData.append('path', abbyPDFURL.pathname)

                        // axios.post('./api-php/authPDFFile.php', formData).then(response => {
                        //     console.log(response)
                        // })

                        // return abbyPDFURL.pathname
                        const encodedAbbyURL = encodeURIComponent(abbyPDFURL)
                        const url = './api-php/readpdf.php?url=' + encodedAbbyURL
                        return url
                        // const localURL = abbyPDFURL.pathname +
                        // '?clientID=' + process.env.VUE_APP_CLIENT_ID +
                        // '&API=' + process.env.VUE_APP_IDENTIFICATION_API_PROD
                        // return localURL
                    }
                })
                .catch(error => {
                    // return error
                    console.log(error)
                })
            default:
                return undefined
        }
    },

    getDocExt (guid) {
        /**
         * call extraction API
         * @param guid GUID of file
         * @returns specific document extraction
         */
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_EXTRACTION_API + 'document/extract/get/' + guid + '?clientID=' + process.env.VUE_APP_CLIENT_ID)
                .then(response => {
                    return response.data
                })
                .catch(error => {
                    return error
                })
            case 'production':
                return axios.get('./api-php/getDocExt.php', {
                    params: {
                        API: process.env.VUE_APP_EXTRACTION_API_PROD,
                        GUID: guid,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                        },
                    })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    return error
                })
            default:
                return undefined
        }
    },

    getUserID (guid) {
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_IDENTIFICATION_API + 'stats/' + guid + '?clientID=' + process.env.VUE_APP_CLIENT_ID)
                .then(response => {
                    return response.data
                })
                .catch(error => {
                    return error
                })

            case 'production':
                return axios.get('./api-php/getUserID.php', {
                    params: {
                        API: process.env.VUE_APP_IDENTIFICATION_API_PROD,
                        GUID: guid,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                        },
                })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    return error
                })
            default:
                return undefined
        }
    },

    deleteFile (guid, UserID) {
        /**
         * call APIs to delete file
         * @guid GUID of selected file
         * @UserID UserID of selected file
         * @returns deletes selected file
         */
        switch (process.env.NODE_ENV) {
            case 'development': {
                const extractionAPIExtract = (process.env.VUE_APP_EXTRACTION_API + 'document/extract/remove/' + guid + '/' + UserID + '/' + process.env.VUE_APP_CLIENT_ID)
                const extractionAPIPending = (process.env.VUE_APP_EXTRACTION_API + 'document/pending/remove/' + guid + '/' + UserID + '/' + process.env.VUE_APP_CLIENT_ID)
                const identificationAPI = (process.env.VUE_APP_IDENTIFICATION_API + 'documents/remove/' + guid + '/' + UserID + '/' + process.env.VUE_APP_CLIENT_ID)

                const extractionExtract = axios.get(extractionAPIExtract)
                const extractionPending = axios.get(extractionAPIPending)
                const identification = axios.get(identificationAPI)
                return axios
                .all([extractionExtract, extractionPending, identification])
                .then(
                  axios.spread((...response) => {
                    return response
                  }),
                )
                .catch(errors => {
                  console.log(errors)
                })
            }
            case 'production':
                return axios.get('./api-php/deleteFile.php', {
                    params: {
                        EXTRACTION_API: process.env.VUE_APP_EXTRACTION_API_PROD,
                        IDENTIFICATION_API: process.env.VUE_APP_IDENTIFICATION_API_PROD,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                        userID: UserID,
                        GUID: guid,
                        },
                    })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    // return error
                    console.log(error)
                })
            default:
                return undefined
        }
    },

    clearData () {
        /**
         * call APIs to clear all data
         * @returns clear all data of stats cards, extracted documents and failed documents
         */
        switch (process.env.NODE_ENV) {
            case 'development':
                return false

            case 'production':
                return axios.get('./api-php/clearData.php', {
                    params: {
                        MATCHER_API: process.env.VUE_APP_MATCHER_API_PROD,
                        EXTRACTION_API: process.env.VUE_APP_EXTRACTION_API_PROD,
                        IDENTIFICATION_API: process.env.VUE_APP_IDENTIFICATION_API_PROD,
                        OCR_SERVER_API: process.env.VUE_APP_OCR_SERVER_PROD,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                        },
                    })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    // return error
                    console.log(error)
                })
            default:
                return undefined
        }
    },

    // sends get request to validate a doc (very weird)
    // Not being used in druckprotocole
    validateDoc (guid) {
        /**
         * call validation API
         * @param guid GUID of document to be validated
         * @returns validates the document
         */
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_EXTRACTION_API + 'documents/stats/validatedata/' + guid + '?clientID=' + process.env.VUE_APP_CLIENT_ID)
                .then(response => {
                    return response.data
                })
                .catch(error => {
                    // return error
                    console.log(error)
                })
            case 'production':
                return axios.get('./api-php/validateDoc.php', {
                    params: {
                        API: process.env.VUE_APP_EXTRACTION_API_PROD,
                        GUID: guid,
                        clientID: process.env.VUE_APP_CLIENT_ID,
                    },
                })
                .then(response => {
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },

    postValidation (guid, validationData, chipsUpdateArray, activeDocName, badExtractions) {
        /**
         * call API to update values of that specific document on server
         * @param guid GUID of specific document
         * @param validationData contains 'extractorRes' updated data to be validated
         * @returns updates key values on server
         */
        return this.getDocExt(guid)
            .then(response => {
                const ExtractedData = response
                // removing extra fields that we are not concerned about
                ExtractedData.fields = ExtractedData.fields.filter(field => {
                    const subKey = field.key.replace(/druckprotocole_|Betriebsinformation_|NoClass_/gi, '')
                    const keyFound = validationData.find(data => data.key === subKey)
                    if (subKey === 'Gemeinde:' || subKey === 'Ortsnetz:' || subKey === 'Nr.:') {
                        return field
                    } else if (keyFound) return field
                })

                // pushing into temp extraction cuz can't push during map
                const tempExtract = []
                ExtractedData.fields.map((field) => {
                /* console.log(field.value) */
                // update betrieb values
                    let selObj = null
                    if (field.key.includes('NoClass_')) {
                        if (field.key === 'NoClass_Gemeinde:' || field.key === 'NoClass_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                                )
                        } else {
                            selObj = validationData.find(
                                (data) => 'NoClass_' + data.key === field.key,
                            )
                        }

                        const selNoClassVal =
                        typeof selObj.value.NoClassVal === 'object'
                            ? selObj.value.NoClassVal.value
                            : selObj.value.NoClassVal

                        if (selNoClassVal !== field.value) {
                            field.editStatus = true
                            field.editedValue = selNoClassVal
                        }
                    } else if (field.key.includes('Betriebsinformation_')) {
                        if (field.key === 'Betriebsinformation_Gemeinde:' || field.key === 'Betriebsinformation_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                            )
                        } else {
                            selObj = validationData.find(
                                (data) => 'Betriebsinformation_' + data.key === field.key,
                            )
                        }

                        const selBetriVal =
                        typeof selObj.value.BetriVal === 'object'
                            ? selObj.value.BetriVal.value
                            : selObj.value.BetriVal

                        if (selBetriVal !== field.value) {
                            field.editStatus = true
                            field.editedValue = selBetriVal
                        }

                        // field.value =
                        //   typeof selObj.value.BetriVal === "object"
                        //     ? selObj.value.BetriVal.value
                        //     : selObj.value.BetriVal;

                        // add key's druck variant as well since key is deleted afterwards
                        const druckVal =
                        typeof selObj.value.druckVal === 'object'
                            ? selObj.value.druckVal.value
                            : selObj.value.druckVal

                        if (druckVal && selObj.key !== 'Ort:') {
                            let druckEquiv = null
                            // if druckEquiv already present in data then update it
                            if (field.key === 'druckprotocole_Gemeinde:') {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_Gemeinde',
                                )
                            } else if (field.key === 'druckprotocole_Ortsnetz:') {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_Ortsnetz',
                                )
                            } else {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_' + selObj.key,
                                )
                            }

                            if (druckEquiv !== -1) {
                                ExtractedData.fields[druckEquiv].editStatus = true
                                ExtractedData.fields[druckEquiv].editedValue = druckVal
                            } else {
                                tempExtract.push({
                                key: 'druckprotocole_' + selObj.key,
                                value: '',
                                editedValue: druckVal,
                                x: 0,
                                y: 0,
                                width: 0,
                                height: 0,
                                page: 1,
                                intKey: null,
                                tag: 'HWR-service',
                                editStatus: true,
                                jsonString: '',
                                })
                            }
                        }
                    } else if (field.key.includes('druckprotocole_')) {
                        // update druck values
                        if (field.key === 'druckprotocole_Gemeinde:' || field.key === 'druckprotocole_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                                )
                        } else if (field.key === 'druckprotocole_Nr.:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Haus-Nr.:',
                                )
                        } else {
                            selObj = validationData.find(
                                (data) => 'druckprotocole_' + data.key === field.key,
                                )
                        }

                        const selDruckVal =
                        typeof selObj.value.druckVal === 'object'
                            ? selObj.value.druckVal.value
                            : selObj.value.druckVal

                        if (selDruckVal !== field.value) {
                            field.editStatus = true
                            field.editedValue = selDruckVal
                        }

                        // field.value =
                        //   typeof selObj.value.druckVal === "object"
                        //     ? selObj.value.druckVal.value
                        //     : selObj.value.druckVal;

                        // add key's Betrieb variant as well since key is deleted afterwards
                        const BetriVal =
                        typeof selObj.value.BetriVal === 'object'
                            ? selObj.value.BetriVal.value
                            : selObj.value.BetriVal
                        if (BetriVal && selObj.key !== 'Ort:') {
                            let BetriEquiv = null
                            // if druckEquiv already present in data then update it
                            if (field.key === 'Betriebsinformation_Gemeinde:') {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_Gemeinde:',
                                )
                            } else if (field.key === 'Betriebsinformation_Ortsnetz:') {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_Ortsnetz:',
                                )
                            } else {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_' + selObj.key,
                                )
                            }

                            if (BetriEquiv !== -1) {
                                ExtractedData.fields[BetriEquiv].editStatus = true
                                ExtractedData.fields[BetriEquiv].editedValue = BetriVal
                            } else {
                                tempExtract.unshift({
                                key: 'Betriebsinformation_' + selObj.key,
                                value: '',
                                editedValue: BetriVal,
                                x: 0,
                                y: 0,
                                width: 0,
                                height: 0,
                                page: 1,
                                intKey: null,
                                tag: 'HWR-service',
                                editStatus: true,
                                jsonString: '',
                                })
                            }
                        }
                    }
                })
                // appending temp extract into main list
                ExtractedData.fields = ExtractedData.fields.concat(tempExtract)
                ExtractedData.fields = ExtractedData.fields.concat(chipsUpdateArray)
                // deleting data already inserted
                ExtractedData.fields.map((field) => {
                    validationData = validationData.filter(
                        (data) =>
                        'Betriebsinformation_' + data.key !== field.key &&
                        'druckprotocole_' + data.key !== field.key &&
                        'NoClass_' + data.key !== field.key,
                    )
                })
                // inserting remaining data
                validationData.map((data) => {
                // pushing both values into extraction
                    if (data.key !== 'Ort:') {
                        if (data.value.BetriVal) {
                            ExtractedData.fields.push({
                            key: 'Betriebsinformation_' + data.key,
                            value: '',
                            editedValue: data.value.BetriVal,
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                        if (data.value.druckVal) {
                            ExtractedData.fields.push({
                            key: 'druckprotocole_' + data.key,
                            value: '',
                            editedValue: data.value.druckVal,
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                        if (data.value.NoClassVal) {
                            ExtractedData.fields.push({
                            key: 'NoClass_' + data.key,
                            value: '',
                            editedValue: data.value.NoClassVal,
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                    }
                })

                // set file name if failed checkbox is true
                if (badExtractions === true) {
                    ExtractedData.badExtractions = true
                    ExtractedData.fileName = activeDocName
                }

                // converting case of userID and clientID because
                // API accepts them in capitalized
                // Why are we doing this? Only God and the Germans know
                ExtractedData.UserID = ExtractedData.userID
                delete ExtractedData.userID
                ExtractedData.ClientID = ExtractedData.clientID
                delete ExtractedData.clientID

                // encapsulating data in data cuz of formatting requirements
                const postData = { data: ExtractedData }
                const postDataProd = JSON.stringify({
                    clientID: process.env.VUE_APP_CLIENT_ID,
                    GUID: guid,
                    API: process.env.VUE_APP_EXTRACTION_API_PROD,
                    data: ExtractedData,
                })
                switch (process.env.NODE_ENV) {
                    case 'development':
                        return axios.post(process.env.VUE_APP_EXTRACTION_API + 'document/extract/update/' + guid + '?clientID=' + process.env.VUE_APP_CLIENT_ID, postData)
                        .then(response => {
                            return response.data
                        })
                        .catch(error => {
                            console.log(error)
                        })
                    case 'production':
                        return axios.post('./api-php/postValidation.php', postDataProd)
                        .then(response => {
                            console.log(response.data)
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })
            .catch(err => {
                return error(err)
            })
    },

    downloadFile (URL) {
        /**
         * downloads file in user's system
         */
        return axios.get(URL, { responseType: 'blob' })
        .then(response => {
            return response.data
        })
    },

    loadValidEquip (equipNr) {
        /**
         * call equipment API
         * @param equipNr equipment number
         * @returns keys and values against EquipmentNr
         */
        console.log('equipnr', equipNr)
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get(process.env.VUE_APP_EQUIPMENT_API + 'equipment/' + equipNr)
                .then(response => {
                    return response.data
                })
                .catch(error => {
                    return error
                })
            case 'production':
                return axios.get('./api-php/loadValidEquip.php', {
                    params: {
                        API: process.env.VUE_APP_EQUIPMENT_API_PROD,
                        equipNr: equipNr,
                        },
                    })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    return error
                })
            default:
                return undefined
        }
    },

    async autocompleteEquipment (equipNr) {
        /**
         * call equipment API
         * @param equipNr equipment number
         * @returns keys and values against EquipmentNr
         */

        switch (process.env.NODE_ENV) {
            case 'development':
                return await axios.get(process.env.VUE_APP_EQUIPMENT_API + 'equipments-autocomplete/' + equipNr)
                .then(response => {
                    return response.data
                })
                .catch(error => {
                    return error
                })
            case 'production':
                return axios.get('./api-php/autocompleteEquip.php', {
                    params: {
                        API: process.env.VUE_APP_EQUIPMENT_API_PROD,
                        equipNr: equipNr,
                        },
                    })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    return error
                })
            default:
                return undefined
        }
    },

    loadUpdatedChips (guid, validationData) {
        return this.getDocExt(guid)
            .then(response => {
                // extracting Feature-instanz, Postleitzahl and Nummer to add in extraction at end
                const tempArray = response.fields.filter(field => {
                    if (field.key.includes('DB_')) {
                        return field
                    }
                })

                const ExtractedData = response
                // removing extra fields that we are not concerned about
                ExtractedData.fields = ExtractedData.fields.filter(field => {
                    const subKey = field.key.replace(/druckprotocole_|Betriebsinformation_|NoClass_/gi, '')
                    const keyFound = validationData.find(data => data.key === subKey)
                    if (subKey === 'Gemeinde:' || subKey === 'Ortsnetz:' || subKey === 'Nr.:') {
                        return field
                    } else if (keyFound) return field
                })

                // pushing into temp extraction cuz can't push during map
                const tempExtract = []
                ExtractedData.fields.map((field) => {
                /* console.log(field.value) */
                // update betrieb values
                    let selObj = null
                    if (field.key.includes('NoClass_')) {
                        if (field.key === 'NoClass_Gemeinde:' || field.key === 'NoClass_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                                )
                        } else {
                            selObj = validationData.find(
                                (data) => 'NoClass_' + data.key === field.key,
                            )
                        }

                        const selNoClassVal =
                        typeof selObj.value.NoClassVal === 'object'
                            ? selObj.value.NoClassVal.value
                            : selObj.value.NoClassVal

                        if (selNoClassVal !== field.value) {
                            field.editStatus = true
                            field.value = selNoClassVal
                        }
                    } else if (field.key.includes('Betriebsinformation_')) {
                        if (field.key === 'Betriebsinformation_Gemeinde:' || field.key === 'Betriebsinformation_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                            )
                        } else {
                            selObj = validationData.find(
                                (data) => 'Betriebsinformation_' + data.key === field.key,
                            )
                        }

                        const selBetriVal =
                        typeof selObj.value.BetriVal === 'object'
                            ? selObj.value.BetriVal.value
                            : selObj.value.BetriVal

                        if (selBetriVal !== field.value) {
                            field.editStatus = true
                            field.value = selBetriVal
                        }

                        // field.value =
                        //   typeof selObj.value.BetriVal === "object"
                        //     ? selObj.value.BetriVal.value
                        //     : selObj.value.BetriVal;

                        // add key's druck variant as well since key is deleted afterwards
                        const druckVal =
                        typeof selObj.value.druckVal === 'object'
                            ? selObj.value.druckVal.value
                            : selObj.value.druckVal

                        if (druckVal && selObj.key !== 'Ort:') {
                            let druckEquiv = null
                            // if druckEquiv already present in data then update it
                            if (field.key === 'druckprotocole_Gemeinde:') {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_Gemeinde',
                                )
                            } else if (field.key === 'druckprotocole_Ortsnetz:') {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_Ortsnetz',
                                )
                            } else {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_' + selObj.key,
                                )
                            }

                            if (druckEquiv !== -1) {
                                ExtractedData.fields[druckEquiv].editStatus = true
                                ExtractedData.fields[druckEquiv].value = druckVal
                            } else {
                                tempExtract.push({
                                key: 'druckprotocole_' + selObj.key,
                                value: druckVal,
                                editedValue: '',
                                x: 0,
                                y: 0,
                                width: 0,
                                height: 0,
                                page: 1,
                                intKey: null,
                                tag: 'HWR-service',
                                editStatus: true,
                                jsonString: '',
                                })
                            }
                        }
                    } else if (field.key.includes('druckprotocole_')) {
                        // update druck values
                        if (field.key === 'druckprotocole_Gemeinde:' || field.key === 'druckprotocole_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                                )
                        } else if (field.key === 'druckprotocole_Nr.:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Haus-Nr.:',
                                )
                        } else {
                            selObj = validationData.find(
                                (data) => 'druckprotocole_' + data.key === field.key,
                                )
                        }

                        const selDruckVal =
                        typeof selObj.value.druckVal === 'object'
                            ? selObj.value.druckVal.value
                            : selObj.value.druckVal

                        if (selDruckVal !== field.value) {
                            field.editStatus = true
                            field.value = selDruckVal
                        }

                        // field.value =
                        //   typeof selObj.value.druckVal === "object"
                        //     ? selObj.value.druckVal.value
                        //     : selObj.value.druckVal;

                        // add key's Betrieb variant as well since key is deleted afterwards
                        const BetriVal =
                        typeof selObj.value.BetriVal === 'object'
                            ? selObj.value.BetriVal.value
                            : selObj.value.BetriVal
                        if (BetriVal && selObj.key !== 'Ort:') {
                            let BetriEquiv = null
                            // if druckEquiv already present in data then update it
                            if (field.key === 'Betriebsinformation_Gemeinde:') {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_Gemeinde:',
                                )
                            } else if (field.key === 'Betriebsinformation_Ortsnetz:') {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_Ortsnetz:',
                                )
                            } else {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_' + selObj.key,
                                )
                            }

                            if (BetriEquiv !== -1) {
                                ExtractedData.fields[BetriEquiv].editStatus = true
                                ExtractedData.fields[BetriEquiv].value = BetriVal
                            } else {
                                tempExtract.unshift({
                                key: 'Betriebsinformation_' + selObj.key,
                                value: BetriVal,
                                editedValue: '',
                                x: 0,
                                y: 0,
                                width: 0,
                                height: 0,
                                page: 1,
                                intKey: null,
                                tag: 'HWR-service',
                                editStatus: true,
                                jsonString: '',
                                })
                            }
                        }
                    }
                })
                // appending temp extract into main list
                ExtractedData.fields = ExtractedData.fields.concat(tempExtract)
                ExtractedData.fields = ExtractedData.fields.concat(tempArray)
                // deleting data already inserted
                ExtractedData.fields.map((field) => {
                    validationData = validationData.filter(
                        (data) =>
                        'Betriebsinformation_' + data.key !== field.key &&
                        'druckprotocole_' + data.key !== field.key &&
                        'NoClass_' + data.key !== field.key,
                    )
                })
                // inserting remaining data
                validationData.map((data) => {
                // pushing both values into extraction
                    if (data.key !== 'Ort:') {
                        if (data.value.BetriVal) {
                            ExtractedData.fields.push({
                            key: 'Betriebsinformation_' + data.key,
                            value: data.value.BetriVal,
                            editedValue: '',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                        if (data.value.druckVal) {
                            ExtractedData.fields.push({
                            key: 'druckprotocole_' + data.key,
                            value: data.value.druckVal,
                            editedValue: '',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                        if (data.value.NoClassVal) {
                            ExtractedData.fields.push({
                            key: 'NoClass_' + data.key,
                            value: data.value.NoClassVal,
                            editedValue: '',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                    }
                })

                // converting case of userID and clientID because
                // API accepts them in capitalized
                // Why are we doing this? Only God and the Germans know
                ExtractedData.UserID = ExtractedData.userID
                delete ExtractedData.userID
                ExtractedData.ClientID = ExtractedData.clientID
                delete ExtractedData.clientID

                // encapsulating data in data cuz of formatting requirements
                const postData = JSON.stringify(ExtractedData)
                
                const postDataProd = JSON.stringify({
                    API: 'http://hwr-service:8080/run-post-processor',
                    json: ExtractedData,
                    originalObject: response
                })

                const formData = new FormData()
                formData.append('json', postData)

                switch (process.env.NODE_ENV) {
                    case 'development':
                        return axios.post('http://dev.deepreader.de:8192/run-post-processor', formData)
                        .then(response => {
                            return response.data
                        })
                        // To test for production
                        // return axios.post('http://localhost/api-php/loadUpdatedChips.php', postDataProd)
                        // .then(response => {
                        //     return response.data
                        // })
                        .catch(error => {
                            console.log(error)
                        })
                    case 'production':
                        return axios.post('./api-php/loadUpdatedChips.php', postDataProd)
                        .then(response => {
                            return response.data
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })
            .catch(err => {
                return error(err)
            })
    },

    updateJsonMatching (guid, validationData) {
        return this.getDocExt(guid)
            .then(response => {
                // extracting Feature-instanz, Postleitzahl and Nummer to add in extraction at end
                const tempArray = response.fields.filter(field => {
                    if (field.key.includes('DB_')) {
                        return field
                    }
                })

                const ExtractedData = response
                // removing extra fields that we are not concerned about
                ExtractedData.fields = ExtractedData.fields.filter(field => {
                    const subKey = field.key.replace(/druckprotocole_|Betriebsinformation_|NoClass_/gi, '')
                    const keyFound = validationData.find(data => data.key === subKey)
                    if (subKey === 'Gemeinde:' || subKey === 'Ortsnetz:' || subKey === 'Nr.:') {
                        return field
                    } else if (keyFound) return field
                })

                // pushing into temp extraction cuz can't push during map
                const tempExtract = []
                ExtractedData.fields.map((field) => {
                /* console.log(field.value) */
                // update betrieb values
                    let selObj = null
                    if (field.key.includes('NoClass_')) {
                        if (field.key === 'NoClass_Gemeinde:' || field.key === 'NoClass_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                                )
                        } else {
                            selObj = validationData.find(
                                (data) => 'NoClass_' + data.key === field.key,
                            )
                        }

                        const selNoClassVal =
                        typeof selObj.value.NoClassVal === 'object'
                            ? selObj.value.NoClassVal.value
                            : selObj.value.NoClassVal

                        if (selNoClassVal !== field.value) {
                            field.editStatus = true
                            field.value = selNoClassVal
                        }
                    } else if (field.key.includes('Betriebsinformation_')) {
                        if (field.key === 'Betriebsinformation_Gemeinde:' || field.key === 'Betriebsinformation_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                            )
                        } else {
                            selObj = validationData.find(
                                (data) => 'Betriebsinformation_' + data.key === field.key,
                            )
                        }

                        const selBetriVal =
                        typeof selObj.value.BetriVal === 'object'
                            ? selObj.value.BetriVal.value
                            : selObj.value.BetriVal

                        if (selBetriVal !== field.value) {
                            field.editStatus = true
                            field.value = selBetriVal
                        }

                        // field.value =
                        //   typeof selObj.value.BetriVal === "object"
                        //     ? selObj.value.BetriVal.value
                        //     : selObj.value.BetriVal;

                        // add key's druck variant as well since key is deleted afterwards
                        const druckVal =
                        typeof selObj.value.druckVal === 'object'
                            ? selObj.value.druckVal.value
                            : selObj.value.druckVal

                        if (druckVal && selObj.key !== 'Ort:') {
                            let druckEquiv = null
                            // if druckEquiv already present in data then update it
                            if (field.key === 'druckprotocole_Gemeinde:') {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_Gemeinde',
                                )
                            } else if (field.key === 'druckprotocole_Ortsnetz:') {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_Ortsnetz',
                                )
                            } else {
                                druckEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'druckprotocole_' + selObj.key,
                                )
                            }

                            if (druckEquiv !== -1) {
                                ExtractedData.fields[druckEquiv].editStatus = true
                                ExtractedData.fields[druckEquiv].value = druckVal
                            } else {
                                tempExtract.push({
                                key: 'druckprotocole_' + selObj.key,
                                value: druckVal,
                                editedValue: '',
                                x: 0,
                                y: 0,
                                width: 0,
                                height: 0,
                                page: 1,
                                intKey: null,
                                tag: 'HWR-service',
                                editStatus: true,
                                jsonString: '',
                                })
                            }
                        }
                    } else if (field.key.includes('druckprotocole_')) {
                        // update druck values
                        if (field.key === 'druckprotocole_Gemeinde:' || field.key === 'druckprotocole_Ortsnetz:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Ort:',
                                )
                        } else if (field.key === 'druckprotocole_Nr.:') {
                            selObj = validationData.find(
                                (data) => data.key === 'Haus-Nr.:',
                                )
                        } else {
                            selObj = validationData.find(
                                (data) => 'druckprotocole_' + data.key === field.key,
                                )
                        }

                        const selDruckVal =
                        typeof selObj.value.druckVal === 'object'
                            ? selObj.value.druckVal.value
                            : selObj.value.druckVal

                        if (selDruckVal !== field.value) {
                            field.editStatus = true
                            field.value = selDruckVal
                        }

                        // field.value =
                        //   typeof selObj.value.druckVal === "object"
                        //     ? selObj.value.druckVal.value
                        //     : selObj.value.druckVal;

                        // add key's Betrieb variant as well since key is deleted afterwards
                        const BetriVal =
                        typeof selObj.value.BetriVal === 'object'
                            ? selObj.value.BetriVal.value
                            : selObj.value.BetriVal
                        if (BetriVal && selObj.key !== 'Ort:') {
                            let BetriEquiv = null
                            // if druckEquiv already present in data then update it
                            if (field.key === 'Betriebsinformation_Gemeinde:') {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_Gemeinde:',
                                )
                            } else if (field.key === 'Betriebsinformation_Ortsnetz:') {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_Ortsnetz:',
                                )
                            } else {
                                BetriEquiv = ExtractedData.fields.findIndex(
                                    (field) => field.key === 'Betriebsinformation_' + selObj.key,
                                )
                            }

                            if (BetriEquiv !== -1) {
                                ExtractedData.fields[BetriEquiv].editStatus = true
                                ExtractedData.fields[BetriEquiv].value = BetriVal
                            } else {
                                tempExtract.unshift({
                                key: 'Betriebsinformation_' + selObj.key,
                                value: BetriVal,
                                editedValue: '',
                                x: 0,
                                y: 0,
                                width: 0,
                                height: 0,
                                page: 1,
                                intKey: null,
                                tag: 'HWR-service',
                                editStatus: true,
                                jsonString: '',
                                })
                            }
                        }
                    }
                })
                // appending temp extract into main list
                ExtractedData.fields = ExtractedData.fields.concat(tempExtract)
                ExtractedData.fields = ExtractedData.fields.concat(tempArray)
                // deleting data already inserted
                ExtractedData.fields.map((field) => {
                    validationData = validationData.filter(
                        (data) =>
                        'Betriebsinformation_' + data.key !== field.key &&
                        'druckprotocole_' + data.key !== field.key &&
                        'NoClass_' + data.key !== field.key,
                    )
                })
                // inserting remaining data
                validationData.map((data) => {
                // pushing both values into extraction
                    if (data.key !== 'Ort:') {
                        if (data.value.BetriVal) {
                            ExtractedData.fields.push({
                            key: 'Betriebsinformation_' + data.key,
                            value: data.value.BetriVal,
                            editedValue: '',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                        if (data.value.druckVal) {
                            ExtractedData.fields.push({
                            key: 'druckprotocole_' + data.key,
                            value: data.value.druckVal,
                            editedValue: '',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                        if (data.value.NoClassVal) {
                            ExtractedData.fields.push({
                            key: 'NoClass_' + data.key,
                            value: data.value.NoClassVal,
                            editedValue: '',
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            page: 1,
                            intKey: null,
                            tag: 'HWR-service',
                            editStatus: true,
                            jsonString: '',
                            })
                        }
                    }
                })

                // converting case of userID and clientID because
                // API accepts them in capitalized
                // Why are we doing this? Only God and the Germans know
                ExtractedData.UserID = ExtractedData.userID
                delete ExtractedData.userID
                ExtractedData.ClientID = ExtractedData.clientID
                delete ExtractedData.clientID

                // encapsulating data in data cuz of formatting requirements
                const postData = JSON.stringify(ExtractedData)
                const postDataProd = JSON.stringify({
                    API: 'http://hwr-service:8080/update-sc-comp-set',
                    json: ExtractedData,
                })

                const formData = new FormData()
                formData.append('json', postData)

                switch (process.env.NODE_ENV) {
                    case 'development':
                        return axios.post('http://dev.deepreader.de:8192/update-sc-comp-set', formData)
                        .then(response => {
                            return response.data
                        })
                        .catch(error => {
                            console.log(error)
                        })
                    case 'production':
                        return axios.post('./api-php/updateJsonMatching.php', postDataProd)
                        .then(response => {
                            return response.data
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })
            .catch(err => {
                return error(err)
            })
    },

    prepareValidatedDoc () {
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get('http://dev.deepreader.de:8096/startDownloading/validatedDocs/druckprotokole')
                .then(response => {
                    // console.log('prepare validated doc', response.data)
                    return response.data
                })
                .catch(error => {
                    return error
                })
            case 'production':
                return axios.get('./api-php/prepareValidatedDoc.php', {
                    params: {
                        API: process.env.VUE_APP_DOWNLOADER_API_PROD,
                    },
                })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    return error
                })
            default:
                return undefined
        }
    },

    getValidatedDocList () {
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get('http://dev.deepreader.de:8096/downloads/all/druckprotokole')
                .then(response => {
                    // console.log('validated list', response.data)
                    return response.data
                })
                .catch(error => {
                    return error
                })
            case 'production':
                return axios.get('./api-php/getValidatedDocList.php', {
                    params: {
                        API: process.env.VUE_APP_DOWNLOADER_API_PROD,
                    },
                })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    return error
                })
            default:
                return undefined
        }
    },

    downloadValidatedDoc (GUID) {
        switch (process.env.NODE_ENV) {
            case 'development':
                return axios.get('http://dev.deepreader.de:8096/download?guid=' + GUID + '&clientID=druckprotokole', { responseType: 'blob' })
                .then(response => {
                    // console.log('download', response.data)
                    return response.data
                })
                .catch(error => {
                    return error
                })
            case 'production':
                return axios.get('./api-php/downloadValidatedDoc.php', {
                    params: {
                        GUID: GUID,
                        API: process.env.VUE_APP_DOWNLOADER_API_PROD,
                    },
                    responseType: 'blob',
                })
                .then(response => {
                    // TODO: handle for empty response
                    return response.data
                })
                .catch(error => {
                    return error
                })
            default:
                return undefined
        }
    },
}
