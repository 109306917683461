<template>
  <div>
    <div
      id="Documents"
      class="text-sm-h3 mb-md-0 mb-sm-4 d-flex justify-space-between"
    >
      Documents
      <div class="text-overline indigo--text text--darken-2">
        <v-subheader>Click on a GUID to open details</v-subheader>
      </div>
    </div>

    <v-tabs
      v-model="activeTabs"
      background-color="transparent"
      class="mb-6"
    >
      <!-- Tab Extracted -->
      <v-tab
        href="#tab-1"
        @click="tabExtracted"
      >
        Extracted
      </v-tab>
      <v-tab-item value="tab-1">
        <section
          title="Documents Table"
          style="padding-top: 20px;"
        >
          <template>
            <div
              class="btn-group ml-1"
              style="position: absolute"
            >
              <div v-if="selectedFile.length <= 0">
                <v-btn
                  class="mr-2 custom-primary-button"
                  density="compact"
                  @click="onEmptyCheckboxClick"
                >
                  Select All
                </v-btn>
              </div>

              <div v-if="selectedFile.length > 0 && selectedFile.length < allDocsData.length">
                <v-btn
                  class="mr-2 custom-primary-button"
                  size="x-small"
                  density="compact"
                  @click="onEmptyCheckboxClick"
                >
                  Select All
                </v-btn>
                <v-btn
                  class="custom-primary-button mr-2"
                  size="x-small"
                  density="compact"
                  @click="onPartialCheckboxClick"
                >
                  Un-Select All
                </v-btn>
                <v-btn
                  class="mr-2 custom-danger-button"
                  size="x-small"
                  density="compact"
                  @click="dialogDeleteOpen"
                >
                  Delete
                </v-btn>
              </div>

              <div v-if="selectedFile.length == allDocsData.length">
                <v-btn
                  size="x-small"
                  class="mr-2 custom-primary-button"
                  density="compact"
                  @click="onPartialCheckboxClick"
                >
                  Un-Select All
                </v-btn>
                <v-btn
                  class="custom-danger-button"
                  size="x-small"
                  density="compact"
                  @click="dialogDeleteOpen"
                >
                  Delete
                </v-btn>
              </div>
            </div>

            <bootstrapTable
              ref="table"
              id="passedfiles"
              :columns="allDocsHeader"
              :data="allDocsData"
              class="table table-custom"
              data-cookie="true"
              data-cookie-id-table="saveId"
              data-search="true"
              data-show-toggle="true"
              data-show-columns="true"
              data-show-export="true"
              data-export-types="['json', 'xml', 'csv', 'txt', 'excel']"
              data-minimum-count-columns="2"
              data-pagination="true"
              data-page-size="10"
              data-page-list="[25, 50, 100, ALL]"
              @on-click-row="onRowClick"
            />
            <v-dialog
              v-model="dialogDelete"
              transition="dialog-bottom-transition"
              max-width="600"
            >
              <v-card
                v-if="!loadDelFileApi"
              >
                <v-toolbar
                  color="error"
                  dark
                >
                  Attention!
                </v-toolbar>
                <v-card-text>
                  <div class="text-h5">
                    Are you sure you want to delete this file?
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="error"
                    elevation="2"
                    outlined
                    @click="deleteFile"
                  >
                    Yes
                  </v-btn>
                  <v-btn
                    text
                    @click="dialogDeleteClose"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card
                v-else-if="loadDelFileApi"
                color="primary"
                dark
              >
                <v-card-text>
                  Please stand by
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </section>
      </v-tab-item>

      <!-- Tab Failed -->
      <v-tab
        href="#tab-2"
        @click="tabFailed"
      >
        Failed
      </v-tab>
      <v-tab-item value="tab-2">
        <section
          title="Failed Documents Table"
          style="padding-top: 20px;"
        >
          <template>
            <div
              class="btn-group ml-1"
              style="position: absolute"
            >
              <div v-if="selectedFile.length <= 0">
                <v-btn
                  class="mr-2 custom-primary-button"
                  density="compact"
                  @click="onEmptyCheckboxClick"
                >
                  Select All
                </v-btn>
              </div>

              <div v-if="selectedFile.length > 0 && selectedFile.length < allDocsData.length">
                <v-btn
                  class="mr-2 custom-primary-button"
                  size="x-small"
                  density="compact"
                  @click="onEmptyCheckboxClick"
                >
                  Select All
                </v-btn>
                <v-btn
                  class="custom-primary-button mr-2"
                  size="x-small"
                  density="compact"
                  @click="onPartialCheckboxClick"
                >
                  Un-Select All
                </v-btn>
                <v-btn
                  class="mr-2 custom-danger-button"
                  size="x-small"
                  density="compact"
                  @click="dialogDeleteOpen"
                >
                  Delete
                </v-btn>
              </div>

              <div v-if="selectedFile.length == allDocsData.length">
                <v-btn
                  size="x-small"
                  class="mr-2 custom-primary-button"
                  density="compact"
                  @click="onPartialCheckboxClick"
                >
                  Un-Select All
                </v-btn>
                <v-btn
                  class="custom-danger-button"
                  size="x-small"
                  density="compact"
                  @click="dialogDeleteOpen"
                >
                  Delete
                </v-btn>
              </div>
            </div>

            <bootstrapTable
              ref="tablefailed"
              id="failedfiles"
              :columns="failedDocsHeader"
              :data="failedDocsData"
              class="table table-custom"
              data-cookie="true"
              data-cookie-id-table="saveId"
              data-search="true"
              data-show-toggle="true"
              data-show-columns="true"
              data-show-export="true"
              data-checkbox-header="true"
              data-export-types="['json', 'xml', 'csv', 'txt', 'excel']"
              data-minimum-count-columns="2"
              data-pagination="true"
              data-page-size="10"
              data-page-list="[25, 50, 100, ALL]"
              @on-click-row="onRowClick"
            />
            <v-dialog
              v-model="dialogDelete"
              transition="dialog-bottom-transition"
              max-width="600"
            >
              <v-card
                v-if="!loadDelFileApi"
              >
                <v-toolbar
                  color="error"
                  dark
                >
                  Attention!
                </v-toolbar>
                <v-card-text>
                  <div class="text-h5">
                    Are you sure you want to delete this file?
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="error"
                    elevation="2"
                    outlined
                    @click="deleteFile"
                  >
                    Yes
                  </v-btn>
                  <v-btn
                    text
                    @click="dialogDeleteClose"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card
                v-else-if="loadDelFileApi"
                color="primary"
                dark
              >
                <v-card-text>
                  Please stand by
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </section>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
  export default {
    name: 'AllDocs',

    model: {
      prop: 'selectedFile',
      event: 'update',
    },

    props: {
      allDocsData: {
        type: Array,
        default () {},
      },
      failedDocsData: {
        type: Array,
        default () {},
      },
      tableLoading: {
        type: Boolean,
        default () {},
      },
      loadDelFileApi: {
        type: Boolean,
        default () {},
      },
      selectedFile: {
        type: Array,
        default () {},
      },
      dialogDelete: {
        type: Boolean,
        default () {},
      },
      activeTab: {
        type: String,
        default () {},
      },
      itemsPerPage: {
        type: Number,
        default () {},
      },
      pagination: {
        type: Number,
        default () {},
      },
    },

    data () {
      return {
        // headers for the all docs table
        selectedGUIDDocuments: [],
        allDocsHeader: [
          {
            field: 'requeue',
            title: '',
            align: 'center',
            clickToSelect: false,
            formatter: operateFormatterRequeue,
          },
          {
            title: 'Time',
            field: 'time',
            sortable: true,
            formatter: dateFormatter,
          },
          {
            title: 'GUID',
            field: 'guid',
            sortable: true,
            formatter: guidFormatter,
          },
          {
            title: 'File Name',
            field: 'fileName',
            sortable: true,
          },
          {
            title: 'Validated',
            field: 'validated',
            sortable: true,
          },
          {
            title: 'Document Class',
            field: 'documentClass',
            sortable: true,
          },
          {
            title: 'City',
            field: 'city',
            sortable: true,
          },
          {
            title: 'Detected Language',
            field: 'detectedLanguage',
            sortable: true,
            formatter: langFormatter,
            events: window.operateEvents,
          },
        ],

        failedDocsHeader: [
          {
            field: 'requeue',
            title: '',
            align: 'center',
            clickToSelect: false,
            formatter: operateFormatterRequeue,
          },
          {
            title: 'Time',
            field: 'time',
            sortable: true,
            formatter: dateFormatter,
          },
          {
            title: 'GUID',
            field: 'guid',
            sortable: true,
            formatter: guidFormatter,
          },
          {
            title: 'File Name',
            field: 'fileName',
            sortable: true,
          },
          {
            title: 'Status',
            field: 'status',
            sortable: true,
          },
          {
            text: 'Error',
            value: 'error',
            sortable: true,
          },
        ],
      }
    },

    computed: {
      selectedFileVal: {
        get () {
          return this.selectedFile
        },
        set (selectedFile) {
          this.$emit('update', selectedFile)
        },
      },
      activeTabs: {
        get () {
          return this.activeTab
        },
        set (activeTab) {
          this.$emit(activeTab)
        },
      },
      paginationPage: {
        get () {
          return this.pagination
        },
        set (pagination) {
          this.$emit('paginations', pagination)
        },
      },
      itemPerPage: {
        get () {
          return this.itemsPerPage
        },
        set (itemsPerPage) {
          this.$emit('itemPerPage', itemsPerPage)
        },
      },

    },

    mounted () {
      window.jQuery(document).on('click', '.requeue-checkbox', (evt) => {
        const guid = window.jQuery(evt.currentTarget).data('guid')
        const index = this.selectedFileVal.findIndex(data => data === guid)
        if (index < 0) {
          selectedGUIDDocuments.push(guid)
          this.selectedFileVal.push(guid)
        } else {
          selectedGUIDDocuments.splice(index, 1)
          this.selectedFileVal.splice(index, 1)
        }
      })

      window.jQuery(document).on('click', '#passedfiles .export.btn-group .dropdown-item', function (evt) {
        StopEvent(evt)
        let type = window.jQuery(this).data('type')
        window.jQuery('#passedfiles').tableExport({type: type});
      })
    },

    methods: {
      loadGUID (guid, validated, fileName, id, index) {
        this.$emit('loadGUID', guid, validated, fileName, id, index)
      },
      deleteFile () {
        this.$emit('deleteFile')
      },
      closePdf () {
        this.$emit('closePdf')
      },
      dialogDeleteOpen () {
        this.$emit('dialogDeleteOpen')
      },
      dialogDeleteClose () {
        this.$emit('dialogDeleteClose')
      },
      tabExtracted () {
        this.$emit('tabExtracted')
        selectedGUIDDocuments = []
        this.selectedFileVal = []
        if (this.$refs.table) {
          this.$refs.table.load(this.allDocsData)
        }
        if (this.$refs.tablefailed) {
          this.$refs.tablefailed.load(this.failedDocsData)
        }
      },
      tabFailed () {
        this.$emit('tabFailed')
        selectedGUIDDocuments = []
        this.selectedFileVal = []
        if (this.$refs.table) {
          this.$refs.table.load(this.allDocsData)
        }
        if (this.$refs.tablefailed) {
          this.$refs.tablefailed.load(this.failedDocsData)
        }
      },
      setItems (items) {
        this.$emit('setItems', items)
      },
      setItemsFailed (items) {
        this.$emit('setItemsFailed', items)
      },
      onRowClick (row, element, field) {
        const telement = window.jQuery(element)
        const index = telement.data('index')
        if (field === 'guid') {
          this.$emit('loadGUID', row.guid, true, row.fileName, row.id, index)
        }
      },
      onEmptyCheckboxClick () {
        selectedGUIDDocuments = []
        this.selectedFileVal.splice(0)

        this.allDocsData.forEach(item => {
          this.selectedFileVal.push(item.guid)
          selectedGUIDDocuments.push(item.guid)
        })

        if (this.$refs.table) {
          this.$refs.table.load(this.allDocsData)
        }
        if (this.$refs.tablefailed) {
          this.$refs.tablefailed.load(this.failedDocsData)
        }
      },
      onPartialCheckboxClick () {
        selectedGUIDDocuments = []
        this.selectedFileVal.splice(0)
        if (this.$refs.table) {
          this.$refs.table.load(this.allDocsData)
        }
        if (this.$refs.tablefailed) {
          this.$refs.tablefailed.load(this.failedDocsData)
        }
      },
    },

  }
  var docDataDict = {}
  var selectedGUIDDocuments = []
  function operateFormatterRequeue (value, row, index) {
    docDataDict[index] = row
    const tguid = row.guid
    const selected = isInSelectedGUID(tguid) ? 'checked' : ''
    return [
      '<input style="margin-top: -5px;" class="form-check-input requeue-checkbox" ' + selected + ' type="checkbox" value="" data-guid="' + tguid + '" data-requeue-checkbox-id=' + index + ' id=' + index + '>',
    ].join('')
  }
  function isInSelectedGUID (guid) {
    const index = selectedGUIDDocuments.findIndex(data => data === guid)
    return index >= 0
  }
  function StopEvent(pE)
  {
    if (!pE)
      if (window.event)
        pE = window.event;
      else
        return;
    if (pE.cancelBubble != null)
      pE.cancelBubble = true;
    if (pE.stopPropagation)
      pE.stopPropagation();
    if (pE.preventDefault)
      pE.preventDefault();
    if (window.event)
      pE.returnValue = false;
    if (pE.cancel != null)
      pE.cancel = true;
  }  // StopEvent
  function langFormatter (value) {
    var icon = ''
    if (value === 'GermanStandard') {
      icon = '<span class="flag-icon flag-icon-de"></span>'
    } else if (value === 'EnglishUnitedStates') {
      icon = '<span class="flag-icon flag-icon-us center"></span>'
    } else {
      return value
    }
    return icon
  }
  function dateFormatter (value) {
    var date = new Date(value)
    value = date.toLocaleString()
    return value
  }
  function guidFormatter (value) {
    return '<span class="text-golden doc-guid">' + value + '</span>'
  }
  window.jQuery.extend(window.jQuery.fn.bootstrapTable.defaults.icons, {
    paginationSwitchDown: 'fa fa-caret-square-down',
    paginationSwitchUp: 'fa fa-caret-square-up',
    refresh: 'fa fa-sync',
    toggleOff: 'fa fa-toggle-off',
    toggleOn: 'fa fa-toggle-on',
    columns: 'fa fa-th-list',
    detailOpen: 'fa fa-plus',
    detailClose: 'fa fa-minus',
    fullscreen: 'fa fa-arrows-alt',
    search: 'fa fa-search',
    clearSearch: 'fa fa-trash',
    download: 'fa fa-download',
    bootstrap5: 'fa fa-download',
    export: 'fa fa-download',
  })
</script>

<style>
  .table-custom {
    background-color: white !important;
    padding: 10px;
  }

  .table-custom thead tr,
  .table-custom tbody tr
   {
    height: 50px;
  }

  .table-custom thead tr td,
  .table-custom tbody tr td
   {
    padding: 10px;
  }

  .bootstrap-table .btn-secondary {
    background-color: #ffffff !important;
    border: 1px solid #EB8C00;
    color: #EB8C00;
  }

  .text-golden {
    color: #4caf50;
    cursor: pointer;
  }

  .custom-primary-button {
    background-color: white !important;
    border: 1px solid #EB8C00 !important;
    color: #EB8C00 !important;
  }

  .custom-danger-button {
    background-color: white !important;
    border: 1px solid red !important;
    color: red !important;
  }
</style>
