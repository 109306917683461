<template>
  <v-card
    v-if="pdfData"
    elevation="4"
    style="width:100%; minHeight:600px"
  >
    <!-- Title -->
    <v-card-title>
      <div class="text-sm-h3 mr-5">
        {{ activeDocName }}
      </div>
      <div v-if="!(extractorRes.badExtractions === null || extractorRes.badExtractions === false)">
        <span v-if="validationTabVal === 'BetriTab'">
          <v-progress-circular
            v-if="chipsLoader"
            indeterminate
            color="primary"
          />
          <span
            v-for="field in chipsData"
            v-else
            v-if="field.value !== ''"
            :key="field.key"
          >
            <v-chip
              v-if="field.value !== 'BIS_DP'"
              :color="field.color"
              label
              class="mx-1"
            >
              {{ field.value }}
            </v-chip>
            <span v-if="field.value == 'BIS_DP'">
              <v-chip
                color="rgba(100, 19, 41, 0.4)"
                label
                class="mx-1"
              >
                BIS
              </v-chip>
              <v-chip
                color="rgba(100, 19, 41, 0.4)"
                label
                class="mx-1"
              >
                DP
              </v-chip>
            </span>
          </span>
        </span>
        <span
          v-else-if="validationTabVal === 'DruckTab'"
        >
          <v-progress-circular
            v-if="chipsLoader"
            indeterminate
            color="primary"
          />
          <span
            v-for="field in chipsData"
            v-else
            :key="field.key"
          >
            <v-chip
              v-if="field.value !== 'BIS_DP'"
              :color="field.color"
              label
              class="mx-1"
            >
              {{ field.value }}
            </v-chip>
            <span v-if="field.value == 'BIS_DP'">
              <v-chip
                color="rgba(100, 19, 41, 0.4)"
                label
                class="mx-1"
              >
                BIS
              </v-chip>
              <v-chip
                color="rgba(100, 19, 41, 0.4)"
                label
                class="mx-1"
              >
                DP
              </v-chip>
            </span>
          </span>
        </span>
        <span
          v-else-if="validationTabVal === 'NoClassTab'"
        >
          <v-progress-circular
            v-if="chipsLoader"
            indeterminate
            color="primary"
          />
          <span
            v-for="field in chipsData"
            v-else
            :key="field.key"
          >
            <v-chip
              v-if="field.value"
              :color="field.color"
              label
              class="mx-1"
            >
              {{ field.value }}
            </v-chip>
          </span>
        </span>
      </div>

      <v-checkbox
        v-model="failedDocCheck"
        class="ml-3"
        label="Failed"
        :disabled="currValidated ? true : false"
        @click="setFailedDocName()"
      />

      <v-spacer />
      <v-btn
        icon
        color="red"
        style="backgroundColor:#fff !important"
        @click="closePdf"
      >
        <v-icon>mdi-close-box</v-icon>
      </v-btn>
    </v-card-title>
    <!-- Content -->
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          lg="8"
        >
          <input
            id="pdfURI"
            type="hidden"
            :pdfData="pdfData"
          >
          <pdfjs-viewer :path="`${pdfData}`" />
          <!-- <pdfjs-viewer :path="pdfData" /> -->
          <!-- <embed
              width="100%"
              height="750px"
              style="minHeight: 750px"
              frameBorder="0"
              scrolling="auto"
              type="application/pdf"
              :src="pdfData"
            > -->
        </v-col>
        <v-col
          cols="12"
          sm="12"
          lg="4"
          class="d-flex flex-column justify-space-around"
        >
          <section
            class="flex-grow-1 d-flex flex-column"
          >
            <div
              v-if="currValidated"
              class="d-flex justify-end"
            >
              <v-card
                outlined
                style="borderColor: green; margin: 0"
              >
                <v-card-text
                  class="px-2 py-1"
                >
                  <span class="h3 font-weight-light green--text mx-2">
                    Validated
                  </span>
                  <v-icon

                    color="green"
                  >
                    mdi-sticker-check-outline
                  </v-icon>
                </v-card-text>
              </v-card>
            </div>
            <div
              class="d-flex justify-center my-4 mr-2"
            >
              <v-btn
                v-if="currValidated"
                color="light-blue darken-3"
                elevation="2"
                medium
                :x-small="$vuetify.breakpoint.xsOnly"
                @click="downloadPDF"
              >
                Download
              </v-btn>

              <v-btn
                v-else-if="!currValidated && !disabledRes"
                color="primary"
                elevation="2"
                medium
                :x-small="$vuetify.breakpoint.xsOnly"
                :loading="validateLoad"
                @click="validateDoc(activeGuid)"
              >
                Validate
              </v-btn>
              <v-tooltip
                v-else-if="disabledRes"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    bordered
                    color="error"
                    icon="mdi-lock"
                    overlap
                    offset-x="30"
                  >
                    <v-btn
                      class="white--text"
                      color="error"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                    >
                      Validation Disabled
                    </v-btn>
                  </v-badge>
                </template>
                <span>Contact admin for more information</span>
              </v-tooltip>
              <v-btn
                elevation="2"
                medium
                :x-small="$vuetify.breakpoint.xsOnly"
                outlined
                text
                color="primary"
                min-width="140px"
                class="mr-5"
                :disabled="isFirstPdf"
                :loading="prevDocBtnLoader"
                @click="goToPrevDoc"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-arrow-left-bold-outline
                </v-icon>
                Previous
              </v-btn>
              <v-btn
                elevation="2"
                medium
                :x-small="$vuetify.breakpoint.xsOnly"
                outlined
                text
                color="primary"
                min-width="140px"
                :disabled="isLastPdf"
                :loading="nextDocBtnLoader"
                @click="goToNextDoc"
              >
                Next
                <v-icon
                  right
                  dark
                >
                  mdi-arrow-right-bold-outline
                </v-icon>
              </v-btn>
            </div>
            <!-- <v-progress-linear
              v-if="extractorRes.docConfidence"
              class="mb-2"
              :color="extractorRes.docConfidence.barcolor"
              height="25"
              :value="extractorRes.docConfidence.score"
              rounded
            >
              <strong>Confidence Score {{ extractorRes.docConfidence.score }}%</strong>
            </v-progress-linear> -->
            <div
              class="flex-grow-1"
              style="maxHeight:600px"
            >
              <v-tabs
                v-model="validationTabVal"
              >
                <v-tab
                  v-if="extractorRes.BetriPage"
                  id="BetriebTab"
                  key="Betrieb"
                  href="#BetriTab"
                  :page="extractorRes.BetriPage"
                  @click="validationTabVal = 'BetriTab'"
                >
                  Betriebsinformation
                </v-tab>
                <v-tab
                  v-if="extractorRes.druckPage"
                  id="druckTab"
                  key="druck"
                  href="#DruckTab"
                  :page="extractorRes.druckPage"
                  @click="validationTabVal = 'DruckTab'"
                >
                  Druckprotokolle
                </v-tab>
                <v-tab
                  v-if="extractorRes.NoClassPage"
                  id="NoClassTab"
                  key="NoClass"
                  href="#NoClassTab"
                  :page="extractorRes.NoClassPage"
                  @click="validationTabVal = 'NoClassTab'"
                >
                  No Class
                </v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="validationTabVal"
              >
                <v-tab-item
                  v-if="extractorRes.BetriPage"
                  key="BetriebTabItem"
                  value="BetriTab"
                  eager
                  class="mt-11"
                  transition="slide-x-transition"
                >
                  <div
                    v-for="field in extractorRes.fields"
                    :key="field.key"
                  >
                    <span
                      v-if="field.id !=='anlagenakte' && field.id !=='strabenakte'"
                      class="d-flex justify-space-between"
                    >
                      <v-text-field
                        :value="field.key"
                        :disabled="disabledRes"
                        filled
                        tabindex="-1"
                        class="mr-4 font-weight-bold"
                        style="maxWidth:160px; pointerEvents: none"
                      >
                        <template v-slot:append>
                          <v-icon :color="field.color">
                            mdi-square-rounded
                          </v-icon>
                        </template>
                      </v-text-field>
                      <v-text-field
                        v-if="(field.id=='equipNum' || field.id =='anschlubNum') && equipNumsAutoComplete.length <= 0"
                        :ref="'betrieb_'+field.id"
                        v-model="field.value.BetriVal"
                        :loading="equipNumLoader"
                        :disabled="disabledRes"
                        :readonly="currValidated ? true : false"
                        :value="field.value.BetriVal"
                        outlined
                        :class="field.textFieldColorClass"
                        @change="loadUpdatedChips(activeGuid)"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="field.edited.BetriEdit"
                            style="font-size: 10px !important"
                          >
                          mdi-circle red--text
                          </v-icon>
                        </template>
                      </v-text-field>
                      <v-combobox
                        v-else-if="(field.id=='equipNum' || field.id =='anschlubNum') && equipNumsAutoComplete.length > 0"
                        :ref="'betrieb_'+field.id"
                        v-model="field.value.BetriVal"
                        :loading="equipNumLoader"
                        :value="field.value.BetriVal"
                        :items="equipNumsAutoComplete"
                        :readonly="currValidated ? true : false"
                        outlined
                        :search-input.sync="eqInput"
                        :class="field.textFieldColorClass"
                        @change="loadValidKeys"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="!field.edited.BetriEdit"
                          >
                            mdi-menu-down primary--text
                          </v-icon>
                          <v-icon
                            v-else-if="field.edited.BetriEdit"
                          >
                          mdi-menu-down red--text
                          </v-icon>
                        </template>
                      </v-combobox>

                      <v-text-field
                        v-else-if="!field.closest_matches.BetriClosestMatch || field.closest_matches.BetriClosestMatch.length <= 0"
                        :ref="'betrieb_'+field.id"
                        v-model="field.value.BetriVal"
                        :disabled="disabledRes"
                        :readonly="currValidated ? true : false"
                        :value="field.value.BetriVal"
                        outlined
                        :class="field.textFieldColorClass"
                        @change="loadUpdatedChips(activeGuid)"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="field.edited.BetriEdit"
                          >
                          mdi-menu-down red--text
                          </v-icon>
                        </template>
                      </v-text-field>
                      <v-combobox
                        v-else
                        :ref="'betrieb_'+field.id"
                        v-model="field.value.BetriVal"
                        :disabled="disabledRes"
                        :items="field.closest_matches.BetriClosestMatch"
                        item-text="value"
                        item-value="id"
                        :readonly="currValidated ? true : false"
                        outlined
                        :class="field.textFieldColorClass"
                        @change="loadUpdatedChips(activeGuid)"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="!field.edited.BetriEdit"
                          >
                            mdi-menu-down primary--text
                          </v-icon>
                          <v-icon
                            v-else-if="field.edited.BetriEdit"
                           >
                          mdi-menu-down red--text
                          </v-icon>
                        </template>
                        <template
                          v-slot:item="{item}"
                        >
                          <span
                            v-if="item.type == 'match'"
                            class="d-flex flex-row justify-space-between align-center"
                            style="width:100%"
                          >
                            <span>
                              {{ item.value }}
                            </span>
                            <span class="text-caption">
                              {{ item.score }}%
                            </span>
                          </span>
                          <span
                            v-else
                            class="d-flex flex-row justify-space-between align-center"
                            style="width:100%"
                          >
                            <span>
                              {{ item.value }}
                            </span>
                            <span class="text-caption">
                              Model String
                            </span>
                          </span>
                        </template>
                      </v-combobox>
                    </span>
                  </div>
                </v-tab-item>
                <v-tab-item
                  v-if="extractorRes.druckPage"
                  key="druckTabItem"
                  value="DruckTab"
                  eager
                  class="mt-11"
                  transition="slide-x-transition"
                >
                  <div
                    v-for="field in extractorRes.fields"
                    :key="field.key"
                  >
                    <span
                      v-if="field.id !=='equipNum'"
                      class="d-flex justify-space-between"
                    >
                      <v-text-field
                        :value="field.key"
                        :disabled="disabledRes"
                        filled
                        tabindex="-1"
                        class="mr-4 font-weight-bold"
                        style="maxWidth:160px; pointerEvents: none"
                      >
                        <template v-slot:append>
                          <v-icon :color="field.color">
                            mdi-square-rounded
                          </v-icon>
                        </template>
                      </v-text-field>

                      <v-text-field
                        v-if="(field.id=='strabenakte' || field.id=='anlagenakte' || field.id=='anschlubNum') && equipNumsAutoComplete.length <= 0"
                        :ref="'betrieb_'+field.id"
                        v-model="field.value.BetriVal"
                        :loading="equipNumLoader"
                        :disabled="disabledRes"
                        :readonly="currValidated ? true : false"
                        :value="field.value.BetriVal"
                        outlined
                        :class="field.textFieldColorClass"
                        @change="loadUpdatedChips(activeGuid)"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="field.edited.BetriEdit"
                            style="font-size: 10px !important"
                          >
                          mdi-circle red--text
                          </v-icon>
                        </template>
                      </v-text-field>

                      <v-combobox
                        v-else-if="(field.id=='strabenakte' || field.id=='anlagenakte' || field.id=='anschlubNum') && equipNumsAutoComplete.length > 0"
                        :ref="'druck_'+field.id"
                        v-model="field.value.druckVal"
                        :loading="equipNumLoader"
                        :value="field.value.druckVal"
                        :items="equipNumsAutoComplete"
                        :readonly="currValidated ? true : false"
                        outlined
                        :class="field.textFieldColorClass"
                        :search-input.sync="druckeqInput"
                        @change="loadValidKeys"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="!field.edited.BetriEdit"
                          >
                            mdi-menu-down primary--text
                          </v-icon>
                          <v-icon
                            v-else-if="field.edited.druckEdit"
                          >
                          mdi-menu-down red--text
                          </v-icon>
                        </template>
                      </v-combobox>

                      <v-text-field
                        v-else-if="!field.closest_matches.druckClosestMatch || field.closest_matches.druckClosestMatch.length <= 0"
                        :ref="'druck_'+field.id"
                        v-model="field.value.druckVal"
                        :disabled="disabledRes"
                        :readonly="currValidated ? true : false"
                        :value="field.value.druckVal"
                        outlined
                        :class="field.textFieldColorClass"
                        @change="loadUpdatedChips(activeGuid)"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="field.edited.druckEdit"
                          >
                          mdi-menu-down red--text
                          </v-icon>
                        </template>
                      </v-text-field>

                      <v-combobox
                        v-else
                        :ref="'druck_'+field.id"
                        v-model="field.value.druckVal"
                        :disabled="disabledRes"
                        :items="field.closest_matches.druckClosestMatch"
                        item-text="value"
                        item-value="id"
                        :readonly="currValidated ? true : false"
                        outlined
                        :class="field.textFieldColorClass"
                        @change="loadUpdatedChips(activeGuid)"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="!field.edited.BetriEdit"
                          >
                            mdi-menu-down primary--text
                          </v-icon>
                          <v-icon
                            v-else-if="field.edited.druckEdit"
                          >
                          mdi-menu-down red--text
                          </v-icon>
                        </template>
                        <template
                          v-slot:item="{item}"
                        >
                          <span
                            v-if="item.type == 'match'"
                            class="d-flex flex-row justify-space-between align-center"
                            style="width:100%"
                          >
                            <span>
                              {{ item.value }}
                            </span>
                            <span class="text-caption">
                              {{ item.score }}%
                            </span>
                          </span>
                          <span
                            v-else
                            class="d-flex flex-row justify-space-between align-center"
                            style="width:100%"
                          >
                            <span>
                              {{ item.value }}
                            </span>
                            <span class="text-caption">
                              Model String
                            </span>
                          </span>
                        </template>
                      </v-combobox>
                    </span>
                  </div>
                </v-tab-item>
                <v-tab-item
                  v-if="extractorRes.NoClassPage"
                  key="NoClassTabItem"
                  value="NoClassTab"
                  eager
                  class="mt-11"
                  transition="slide-x-transition"
                >
                  <div
                    v-for="field in extractorRes.fields"
                    :key="field.key"
                    class="d-flex justify-space-between"
                  >
                    <v-text-field
                      :value="field.key"
                      :disabled="disabledRes"
                      filled
                      tabindex="-1"
                      class="mr-4 font-weight-bold"
                      style="maxWidth:160px; pointerEvents: none"
                    >
                      <template v-slot:append>
                        <v-icon :color="field.color">
                          mdi-square-rounded
                        </v-icon>
                      </template>
                    </v-text-field>

                    <v-text-field
                        v-if="(field.id=='equipNum' || field.id=='strabenakte' || field.id=='anlagenakte' || field.id=='anschlubNum') && equipNumsAutoComplete.length <= 0"
                        :ref="'betrieb_'+field.id"
                        v-model="field.value.BetriVal"
                        :loading="equipNumLoader"
                        :disabled="disabledRes"
                        :readonly="currValidated ? true : false"
                        :value="field.value.BetriVal"
                        outlined
                        :class="field.textFieldColorClass"
                        @change="loadUpdatedChips(activeGuid)"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="field.edited.BetriEdit"
                            style="font-size: 10px !important"
                          >
                          mdi-circle red--text
                          </v-icon>
                        </template>
                      </v-text-field>


                    <v-combobox
                      v-else-if="(field.id=='equipNum' || field.id=='strabenakte' || field.id=='anlagenakte' || field.id=='anschlubNum') && equipNumsAutoComplete.length > 0"
                      :ref="'betrieb_'+field.id"
                      v-model="field.value.NoClassVal"
                      :loading="equipNumLoader"
                      :value="field.value.NoClassVal"
                      :items="equipNumsAutoComplete"
                      :readonly="currValidated ? true : false"
                      outlined
                      :search-input.sync="eqInput"
                      :class="field.textFieldColorClass"
                      @change="loadValidKeys"
                    >
                      <template v-slot:append>
                        <v-icon
                            v-if="!field.edited.BetriEdit"
                          >
                            mdi-menu-down primary--text
                          </v-icon>
                        <v-icon
                          v-else-if="field.edited.NoClassEdit"
                        >
                        mdi-menu-down red--text
                        </v-icon>
                      </template>
                    </v-combobox>

                    <v-text-field
                      v-else-if="!field.closest_matches.NoClassClosestMatch || field.closest_matches.NoClassClosestMatch.length <= 0"
                      :ref="'betrieb_'+field.id"
                      v-model="field.value.NoClassVal"
                      :disabled="disabledRes"
                      :readonly="currValidated ? true : false"
                      :value="field.value.NoClassVal"
                      outlined
                      :class="field.textFieldColorClass"
                      @change="loadUpdatedChips(activeGuid)"
                    >
                      <template v-slot:append>
                        <v-icon
                          v-if="field.edited.NoClassEdit"
                        >
                        mdi-menu-down red--text
                        </v-icon>
                      </template>
                    </v-text-field>
                    <v-combobox
                      v-else
                      :ref="'betrieb_'+field.id"
                      v-model="field.value.NoClassVal"
                      :disabled="disabledRes"
                      :items="field.closest_matches.NoClassClosestMatch"
                      item-text="value"
                      item-value="id"
                      :readonly="currValidated ? true : false"
                      outlined
                      :class="field.textFieldColorClass"
                      @change="loadUpdatedChips(activeGuid)"
                    >
                      <template v-slot:append>
                        <v-icon
                            v-if="!field.edited.BetriEdit"
                          >
                            mdi-menu-down primary--text
                          </v-icon>
                        <v-icon
                          v-else-if="field.edited.NoClassEdit"
                        >
                        mdi-menu-down red--text
                        </v-icon>
                      </template>
                      <template
                        v-slot:item="{item}"
                      >
                        <span
                          v-if="item.type == 'match'"
                          class="d-flex flex-row justify-space-between align-center"
                          style="width:100%"
                        >
                          <span>
                            {{ item.value }}
                          </span>
                          <span class="text-caption">
                            {{ item.score }}%
                          </span>
                        </span>
                        <span
                          v-else
                          class="d-flex flex-row justify-space-between align-center"
                          style="width:100%"
                        >
                          <span>
                            {{ item.value }}
                          </span>
                          <span class="text-caption">
                            Model String
                          </span>
                        </span>
                      </template>
                    </v-combobox>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
            <div class="text-caption flex-grow-1">
              <v-icon color="primary">
                mdi-circle-medium
              </v-icon>
              values edited by user
            </div>
            <div
              v-if="hidefieldcolor == false"
              class=" mt-n5"
            >
              <div class="text-caption flex-grow-1 d-flex justify-end">
                <v-icon color="green">
                  mdi-minus
                </v-icon>
                Same values
              </div>
              <div class="text-caption flex-grow-1 d-flex justify-end">
                <v-icon color="#377eb8">
                  mdi-minus
                </v-icon>
                Field is empty
              </div>
              <div class="text-caption flex-grow-1 d-flex justify-end">
                <v-icon color="red">
                  mdi-minus
                </v-icon>
                Different values
              </div>
            </div>
          </section>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import PDFJSViewer from '@/components/base/PDFJSViewer.vue'
  import api from '@/services/api'
  export default {
    name: 'OcrResults',
    components: {
      'pdfjs-viewer': PDFJSViewer,
    },

    model: {
      prop: 'validationTab',
      event: 'update',
    },

    props: {
      pdfData: {
        type: String,
        default () {},
      },
      activeDocName: {
        type: String,
        default () {},
      },
      currValidated: {
        type: Boolean,
        default () {},
      },
      isFirstPdf: {
        type: Boolean,
        default () {},
      },
      prevDocBtnLoader: {
        type: Boolean,
        default () {},
      },
      isLastPdf: {
        type: Boolean,
        default () {},
      },
      nextDocBtnLoader: {
        type: Boolean,
        default () {},
      },
      extractorRes: {
        type: Object,
        default () {},
      },
      chipsData: {
        type: Array,
        default () {},
      },
      disabledRes: {
        type: Boolean,
        default () {},
      },
      hidefieldcolor: {
        type: Boolean,
        default () {},
      },
      activeGuid: {
        type: String,
        default () {},
      },
      validateLoad: {
        type: Boolean,
        default () {},
      },
      validationTab: {
        type: String,
        default () {},
      },
      chipsLoader: {
        type: Boolean,
        default () {},
      },
      failedDocCheckbox: {
        type: Boolean,
        default () {},
      },
    },

    data: () => ({
      eqInput: null,
      druckeqInput: null,
      equipNumsAutoComplete: [],
      equipNumLoader: false,
    }),

    computed: {
      validationTabVal: {
        get () {
          return this.validationTab
        },
        set (validationTab) {
          this.$emit('update', validationTab)
        },
      },

      failedDocCheck: {
        get () {
          return this.failedDocCheckbox
        },
        set (failedDocCheckbox) {
          this.$emit('click', failedDocCheckbox)
        },
      },
    },

    watch: {
      async eqInput (equipNum) {
        if (equipNum && equipNum.length > 2) {
          this.equipNumLoader = true
          await api.autocompleteEquipment(equipNum)
            .then(response => {
              this.equipNumLoader = false
              this.equipNumsAutoComplete = response.data.map(elem => elem['Equipmentnummer (SAP)'])
            })
        }
      },

      async druckeqInput (equipNum) {
        if (equipNum && equipNum.length > 2) {
          this.equipNumLoader = true
          await api.autocompleteEquipment(equipNum)
            .then(response => {
              this.equipNumLoader = false
              this.equipNumsAutoComplete = response.data.map(elem => elem['Equipmentnummer (SAP)'])
            })
        }
      },
    },

    methods: {
      goToPrevDoc () {
        this.$emit('goToPrevDoc')
      },
      goToNextDoc () {
        this.$emit('goToNextDoc')
      },
      loadValidKeys (selVal) {
        this.$emit('loadValidKeys', selVal)
      },
      downloadPDF () {
        this.$emit('downloadPDF')
      },
      validateDoc (activeGuid) {
        this.$emit('validateDoc', activeGuid)
      },
      closePdf () {
        this.$emit('closePdf')
      },
      loadUpdatedChips (activeGuid) {
        this.$emit('loadUpdatedChips', activeGuid)
      },
      setFailedDocName () {
        this.$emit('setFailedDocName')
      },
    },
  }
</script>

<style lang="scss">
// .v-text-field--outlined.add-custom-color-green fieldset {
//   border: 2px solid rgba(77, 175, 74, 1) !important;
// }
// .v-text-field--outlined.add-custom-color-grey fieldset {
//   border: 2px solid rgba(124, 124, 124, 0.6) !important;
// }
</style>
