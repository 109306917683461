<style>
.v-menu__content::-webkit-scrollbar-thumb {
    background: #4CAF50;
    border-radius: 8px;
}
.v-menu__content::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: white;
}
</style>
<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <!-- show loading bar if updates not loaded yet -->
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-linear
          indeterminate
          color="teal"
        />
      </div>
    </v-container>

    <!-- detailed card row -->
    <v-container v-else>
      <section title="statistics cards">
        <!-- Stats Component -->
        <stats
          :update-res="updateRes"
          :file-upload-loading="fileUploadLoading"
          :ocr-loading="OCRloading"
          :identified-loading="Identifiedloading"
          :extracted-loading="Extractedloading"
          :failed-loading="Failedloading"
          @changeTab="changeTab"
        />

        <!-- Clear Data Button Component -->
        <clear-data
          v-model="clearDataDialog"
          :load-clear-api="loadClearAPI"
          :clear-data-res="clearDataRes"
          :load-validated-doc="loadValidatedDoc"
          :file-upload-loading="fileUploadLoading"
          :update-res="updateRes"
          @deleteDocs="deleteDocs"
          @downloadValidatedDoc="downloadValidatedDoc"
        />
      </section>

      <v-divider
        light
        class="mt-lg-8 mt-md-12 mb-md-6 mt-sm-6 mb-sm-4"
      />
      <section
        title="upload file section"
        style="background: white; padding: 10px; padding-bottom: 30px; border-radius: 5px;"
      >
        <!-- file input row -->
        <!-- making whole row droppable -->
        <!-- solution gotten from: -->
        <!-- https://github.com/vuetifyjs/vuetify/issues/7836#issuecomment-582841222 -->
        <!--
      v-cloak
      @drop.prevent="addDropFile"
      @dragover.prevent
    -->

        <!-- Upload Document Component -->
        <div class="row">
          <div class="offset-md-10 col-md-2">
            <v-autocomplete
              v-model="selectedCity"
              label="Select City"
              :items="citiesRes"
              item-text="gemeinde"
              return-object
            />
          </div>
        </div>

        <upload-doc
          v-model="fileUploaderModel"
          :file-counter="fileCounter"
          :upload-progressbar="uploadProgressbar"
          :progress-bar="progressBar"
          @uploadFile="uploadFile"
        />
      </section>

      <v-divider
        light
        class="mt-lg-8 mt-md-12 mb-md-6 mt-sm-6 mb-sm-4"
      />

      <!-- All Documents Tab Component -->
      <all-docs
        v-model="selectedFile"
        :all-docs-data="allDocsData"
        :failed-docs-data="failedDocsData"
        :table-loading="tableLoading"
        :load-del-file-api="loadDelFileAPI"
        :dialog-delete="dialogDelete"
        :active-tab="activeTab"
        :items-per-page="itemsPerPage"
        :pagination="pagination"
        @loadGUID="loadGUID"
        @deleteFile="deleteFile"
        @closePdf="closePdf"
        @dialogDeleteOpen="dialogDeleteOpen"
        @dialogDeleteClose="dialogDeleteClose"
        @tabExtracted="tabExtracted"
        @tabFailed="tabFailed"
        @setItems="setItems"
        @setItemsFailed="setItemsFailed"
        @paginations="paginations"
        @itemPerPage="itemPerPage"
      />

      <v-divider
        v-if="PDFData"
        id="detailsCard"
        light
        class="my-md-12 my-sm-12"
      />
    </v-container>

    <!-- OCR Results Component -->
    <ocr-results
      v-model="validationTab"
      class="mt-n1"
      :pdf-data="PDFData"
      :active-doc-name="activeDocName"
      :curr-validated="currValidated"
      :is-first-pdf="isFirstPDF"
      :prev-doc-btn-loader="prevDocBtnLoader"
      :is-last-pdf="isLastPDF"
      :next-doc-btn-loader="nextDocBtnLoader"
      :extractor-res="extractorRes"
      :chips-data="chipsData"
      :disabled-res="disabledRes"
      :hidefieldcolor="hidefieldcolor"
      :active-guid="activeGUID"
      :validate-load="validateLoad"
      :chips-loader="chipsLoader"
      :failed-doc-checkbox="failedDocCheckbox"
      @goToPrevDoc="goToPrevDoc"
      @goToNextDoc="goToNextDoc"
      @loadValidKeys="loadValidKeys"
      @downloadPDF="downloadPDF"
      @validateDoc="validateDoc"
      @closePdf="closePdf"
      @loadUpdatedChips="loadUpdatedChips"
      @setFailedDocName="setFailedDocName"
    />

    <!-- dashboard container ends -->

    <!-- Snack Bar Alerts -->
    <v-snackbar
      v-model="clearDataSnack"
      timeout="2000"
    >
      Data cleared successfully!
    </v-snackbar>
    <v-snackbar
      v-model="deleteFileSnack"
      timeout="2000"
    >
      File deleted successfully!
    </v-snackbar>
    <v-snackbar
      v-model="deleteFileFailedSnack"
      timeout="2000"
      color="error"
    >
      Failed to delete this file. Something went wrong!
    </v-snackbar>
    <v-snackbar
      v-model="fileUploadedSnack"
      timeout="2000"
    >
      File uploaded successfully!
    </v-snackbar>
    <v-snackbar
      v-model="validatedSnack"
      timeout="2000"
    >
      Document validated successfully!
    </v-snackbar>
    <v-snackbar
      v-model="genericError"
      timeout="2000"
      color="error"
    >
      Oops... Something went wrong!
    </v-snackbar>
    <v-snackbar
      v-model="strabeSnack"
      timeout="4000"
      color="error"
    >
      <strong>Straße</strong> is required for validation.
    </v-snackbar>
    <v-snackbar
      v-model="reqKeysSnack"
      timeout="4000"
      color="error"
    >
      One of the <strong>Ort</strong> or <strong>Ortsteil</strong> is required for validation.
    </v-snackbar>
    <v-snackbar
      v-model="downloadValidatedSnack"
      timeout="3000"
    >
      There is no Validated document to download!
    </v-snackbar>
  </v-container>
</template>

<script>
  // still needs to be worked out
  // eslint-disable-next-line no-unused-vars
  // function addDropFile (e) {
  //   this.file = e.dataTransfer.files[0]
  // }
  import api from '@/services/api'
  import goTo from 'vuetify/es5/services/goto'
  import { PDFDocument, rgb } from 'pdf-lib'
  // import PDFJSViewer from '@/components/base/PDFJSViewer.vue'
  import Stats from './components/custom/Stats'
  import UploadDoc from './components/custom/UploadDoc'
  import ClearData from './components/custom/ClearData'
  import OcrResults from './components/custom/OcrResults'
  import AllDocs from './components/custom/AllDocs'

  export default {
    name: 'DashboardDashboard',

    components: {
      // eslint-disable-next-line vue/no-unused-components
      // 'pdfjs-viewer': PDFJSViewer,
      Stats,
      UploadDoc,
      ClearData,
      OcrResults,
      AllDocs,
    },

    data () {
      return {
        citiesRes: {},
        selectedCity: {},
        updateRes: {},
        loading: true,
        activeTab: 'tab-1',
        chipsData: [],
        tempguid: '',
        tempvalidated: '',
        tempfileName: '',
        tempid: '', 
        tempindex: '',
        // Keys for validation
        validationKeys: [
          {
            key: 'Equipment-Nr.:',
            id: 'equipNum',
            color: 'rgba(38, 70, 83, 0.45)',
            pdfColor: {
              red: 0.149,
              green: 0.274,
              blue: 0.325,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Ort:',
            id: 'ort',
            color: 'rgba(231, 111, 81, 0.45)',
            pdfColor: {
              red: 0.905,
              green: 0.435,
              blue: 0.317,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Gemeinde:',
            id: 'gemeinde',
            color: 'rgba(231, 111, 81, 0.45)',
            pdfColor: {
              red: 0.905,
              green: 0.435,
              blue: 0.317,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Ortsnetz:',
            id: 'ortsnetz',
            color: 'rgba(231, 111, 81, 0.45)',
            pdfColor: {
              red: 0.905,
              green: 0.435,
              blue: 0.317,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Ortsteil:',
            id: 'ortsteil',
            color: 'rgba(244, 162, 97, 0.45)',
            pdfColor: {
              red: 0.956,
              green: 0.635,
              blue: 0.380,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Straße:',
            id: 'strabe',
            color: 'rgba(42, 157, 143, 0.45)',
            pdfColor: {
              red: 0.164,
              green: 0.615,
              blue: 0.560,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Haus-Nr.:',
            id: 'hausNum',
            color: 'rgba(233, 196, 106, 0.45)',
            pdfColor: {
              red: 0.913,
              green: 0.768,
              blue: 0.415,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Straßenakte:',
            id: 'strabenakte',
            color: 'rgba(38, 70, 83, 0.45)',
            pdfColor: {
              red: 0.149,
              green: 0.274,
              blue: 0.325,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Anlagenakte:',
            id: 'anlagenakte',
            color: 'rgba(38, 70, 83, 0.45)',
            pdfColor: {
              red: 0.149,
              green: 0.274,
              blue: 0.325,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
          {
            key: 'Anschluß-Nr.:',
            id: 'anschlubNum',
            color: 'rgba(38, 70, 83, 0.45)',
            pdfColor: {
              red: 0.149,
              green: 0.274,
              blue: 0.325,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          },
        ],
        cityData: null,
        cityDataLoader: false,
        allDocsData: [],
        tableLoading: true,
        activeDocURL: undefined,
        PDFData: undefined,
        activeDocName: undefined,
        DocValidName: undefined,
        extractorRes: {},
        disabledRes: false,
        currValidated: false,
        activeGUID: '',
        validateLoad: false,
        loadClearAPI: false,
        clearDataRes: undefined,
        clearDataDialog: undefined,
        fileUploadLoading: false,
        fileUploaderModel: null,
        validatedSnack: false,
        fileUploadedSnack: false,
        clearDataSnack: false,
        genericError: false,
        failedDocsData: null,
        checkfailedDocData: null,
        strabeSnack: false,
        reqKeysSnack: false,
        Identifiedloading: false,
        Extractedloading: false,
        OCRloading: false,
        Failedloading: false,
        validationTab: null,
        pdfKey: 0,
        dialogDelete: undefined,
        deleteFileSnack: false,
        deleteFileFailedSnack: false,
        UserID: undefined,
        loadDelFileAPI: false,
        currDocID: 1,
        isFirstPDF: false,
        prevDocBtnLoader: false,
        isLastPDF: false,
        nextDocBtnLoader: false,
        hidefieldcolor: false,
        selectedFile: [],
        chipsUpdateArray: [],
        chipsLoader: false,
        loadValidatedDoc: false,
        validatedDocGUID: '',
        downloadValidatedSnack: false,
        fileCounter: 0,
        uploadProgressbar: 0,
        uploadFileLength: 0,
        progressBar: false,
        fileLength: 0,
        oldequipBetri: '',
        selEquipBetri: '',
        oldAnlagneDruck: '',
        selAnlagneDruck: '',
        failedDocCheckbox: false,
        tempDocName: undefined,
        activeItems: [],
        currentIndex: -1,
        pagination: 1,
        itemsPerPage: 5,
        activeItemsExtracted: [],
        activeItemsFailed: [],
        uploadTime: null,
        ocrTime: null,
        identifiedTime: null,
      }
    },

    computed: {
      totalPages () {
        let totaldocLength = 0
        if (this.activeTab === 'tab-1') {
          totaldocLength = Math.ceil(this.allDocsData.length / this.itemsPerPage)
        } else if (this.activeTab === 'tab-2') {
          totaldocLength = Math.ceil(this.failedDocsData.length / this.itemsPerPage)
        }
        return totaldocLength
      },
    },

    watch: {
      extractorRes: {
        handler (newFields) {
          /**
           * gets selected key values
           * @param newFields selected key
           * @returns identify if value is edited
           * @returns chnages border color of fields on certain rules
           */

          this.validationKeys.map(item => {
            const selField = newFields.fields.find(field => field.key === item.key)

            if (selField && (selField.key === 'Equipment-Nr.:' || selField.key === 'Anschluß-Nr.:')) {
              this.oldequipBetri = item.value.BetriVal
              this.selEquipBetri = selField
                ? (typeof selField.value.BetriVal === 'object'
                  ? selField.value.BetriVal.value : selField.value.BetriVal)
                : ''
            }

            if (selField && (selField.key === 'Anlagenakte:' || selField.key === 'Straßenakte:' || selField.key === 'Anschluß-Nr.:')) {
              this.oldAnlagneDruck = item.value.druckVal
              this.selAnlagneDruck = selField
                ? (typeof selField.value.druckVal === 'object'
                  ? selField.value.druckVal.value : selField.value.druckVal)
                : ''
            }

            // sometimes we get object in the value cuz of dropdown
            let selBetriVal = '';
            if(selField && selField.value && selField.value.BetriVal) {
              selBetriVal = selField
              ? (typeof selField.value.BetriVal === 'object'
                ? selField.value.BetriVal.value : selField.value.BetriVal)
              : ''
            }

            // sometimes we get object in the value cuz of dropdown
            let seldruckVal = '';
            if(selField && selField.value && selField.value.druckVal) {
              seldruckVal = selField
                ? (typeof selField.value.druckVal === 'object'
                  ? selField.value.druckVal.value : selField.value.druckVal)
                : ''
            }

            let selNoClassVal = '';
            if(selField && selField.value && selField.value.NoClassVal) {
              selNoClassVal = selField
                ? (typeof selField.value.NoClassVal === 'object'
                  ? selField.value.NoClassVal.value : selField.value.NoClassVal)
                : ''
            }

            const oldBetriVal = item.value.BetriVal
            const olddruckVal = item.value.druckVal
            const oldNoClassVal = item.value.NoClassVal

            if (selField && selBetriVal !== undefined && selBetriVal !== oldBetriVal) {
              selField.edited.BetriEdit = true
            } else if (selField && selBetriVal !== undefined && selBetriVal === oldBetriVal) {
              selField.edited.BetriEdit = false
            }

            if (selField && seldruckVal !== undefined && seldruckVal !== olddruckVal) {
              selField.edited.druckEdit = true
            } else if (selField && seldruckVal !== undefined && seldruckVal === olddruckVal) {
              selField.edited.druckEdit = false
            }

            if (selField && selNoClassVal !== undefined && selNoClassVal !== oldNoClassVal) {
              selField.edited.NoClassEdit = true
            } else if (selField && selNoClassVal !== undefined && selNoClassVal === oldNoClassVal) {
              selField.edited.NoClassEdit = false
            }

            // conditions for live changing of color for Equipment-Nr
            if (selField && (selField.key === 'Equipment-Nr.:' || selField.key === 'Anlagenakte:' || selField.key === 'Anschluß-Nr.:' || selField.key === 'Straßenakte:')) {
              if (this.extractorRes.BetriPage !== null && this.extractorRes.druckPage !== null) {
                this.hidefieldcolor = false
                if (selField && (this.oldequipBetri !== this.selEquipBetri)) {
                  if ((this.selEquipBetri === '' || this.selAnlagneDruck === '') || (this.selEquipBetri === '' && this.oldAnlagneDruck !== '') || (this.selEquipBetri === '' && this.selAnlagneDruck !== '')) {
                    // field value is empty (blue)
                    selField.textFieldColorClass = 'add-custom-color-blue'
                  } else if ((this.selEquipBetri === this.oldAnlagneDruck && this.oldAnlagneDruck === this.selAnlagneDruck) || this.selEquipBetri === this.selAnlagneDruck || (this.selAnlagneDruck === '' && this.selEquipBetri === '')) {
                    // values are same (green)
                    selField.textFieldColorClass = 'add-custom-color-green'
                  } else if (((this.selEquipBetri !== this.oldAnlagneDruck || this.selEquipBetri !== this.selAnlagneDruck) && this.selEquipBetri !== '' && this.selAnlagneDruck !== '')) {
                    // values are not same (red)
                    selField.textFieldColorClass = 'add-custom-color-red'
                  }
                } else if (selField && (this.oldAnlagneDruck !== this.selAnlagneDruck)) {
                  if ((this.selAnlagneDruck === '' && this.oldequipBetri !== '') || (this.selAnlagneDruck === '' || this.selEquipBetri === '') || (this.selAnlagneDruck === '' && this.selEquipBetri !== '')) {
                    // field value is empty (blue)
                    selField.textFieldColorClass = 'add-custom-color-blue'
                  } else if ((this.selAnlagneDruck === this.oldequipBetri && this.oldequipBetri === this.selEquipBetri) || this.selAnlagneDruck === this.selEquipBetri || (this.selAnlagneDruck === '' && this.selEquipBetri === '')) {
                    // values are same (green)
                    selField.textFieldColorClass = 'add-custom-color-green'
                  } else if (((this.selAnlagneDruck !== this.oldequipBetri || this.selAnlagneDruck !== this.selEquipBetri) && (this.selEquipBetri !== '' && this.selAnlagneDruck !== ''))) {
                    // values are not same (red)
                    selField.textFieldColorClass = 'add-custom-color-red'
                  }
                } else if (selField && (this.oldequipBetri === this.selEquipBetri && this.oldAnlagneDruck === this.selAnlagneDruck)) {
                  if (this.oldequipBetri === '' || this.oldAnlagneDruck === '') {
                    // field value is empty (blue)
                    selField.textFieldColorClass = 'add-custom-color-blue'
                  } else if (this.oldequipBetri === this.oldAnlagneDruck) {
                    // values are same (green)
                    selField.textFieldColorClass = 'add-custom-color-green'
                  } else if (this.oldequipBetri !== this.oldAnlagneDruck && this.oldequipBetri !== '' && this.oldAnlagneDruck !== '') {
                    // values are not same (red)
                    selField.textFieldColorClass = 'add-custom-color-red'
                  }
                }
              } else if (selField) {
                this.hidefieldcolor = true
                selField.textFieldColorClass = 'add-custom-color-grey'
              }
            }

            // conditions for live changing of color text fields
            if (selField && (selField.key !== 'Equipment-Nr.:' && selField.key !== 'Anlagenakte:' && selField.key !== 'Anschluß-Nr.:' && selField.key !== 'Straßenakte:')) {
              if (this.extractorRes.BetriPage !== null && this.extractorRes.druckPage !== null) {
                this.hidefieldcolor = false
                if (selField && (oldBetriVal !== selBetriVal)) {
                  if ((selBetriVal === '' && olddruckVal !== '') || (selBetriVal === '' || seldruckVal === '') || (selBetriVal === '' && seldruckVal !== '')) {
                    // field value is empty (blue)
                    selField.textFieldColorClass = 'add-custom-color-blue'
                  } else if ((selBetriVal === olddruckVal && olddruckVal === seldruckVal) || selBetriVal === seldruckVal || (seldruckVal === '' && selBetriVal === '')) {
                    // values are same (green)
                    selField.textFieldColorClass = 'add-custom-color-green'
                  } else if (((selBetriVal !== olddruckVal || selBetriVal !== seldruckVal) && selBetriVal !== '' && seldruckVal !== '')) {
                    // values are not same (red)
                    selField.textFieldColorClass = 'add-custom-color-red'
                  }
                } else if (selField && (olddruckVal !== seldruckVal)) {
                  if ((seldruckVal === '' && oldBetriVal !== '') || (seldruckVal === '' || selBetriVal === '') || (seldruckVal === '' && selBetriVal !== '')) {
                    // field value is empty (blue)
                    selField.textFieldColorClass = 'add-custom-color-blue'
                  } else if ((seldruckVal === oldBetriVal && oldBetriVal === selBetriVal) || seldruckVal === selBetriVal || (seldruckVal === '' && selBetriVal === '')) {
                    // values are same (green)
                    selField.textFieldColorClass = 'add-custom-color-green'
                  } else if (((seldruckVal !== oldBetriVal || seldruckVal !== selBetriVal) && (selBetriVal !== '' && seldruckVal !== ''))) {
                    // values are not same (red)
                    selField.textFieldColorClass = 'add-custom-color-red'
                  }
                } else if (selField && ((oldBetriVal === selBetriVal) || (olddruckVal === seldruckVal))) {
                  if (oldBetriVal === '' || olddruckVal === '') {
                    // field value is empty (blue)
                    selField.textFieldColorClass = 'add-custom-color-blue'
                  } else if (oldBetriVal === olddruckVal) {
                    // values are same (green)
                    selField.textFieldColorClass = 'add-custom-color-green'
                  } else if (oldBetriVal !== olddruckVal && oldBetriVal !== '' && olddruckVal !== '') {
                    // values are not same (red)
                    selField.textFieldColorClass = 'add-custom-color-red'
                  }
                }
              } else if (selField) {
                this.hidefieldcolor = true
                selField.textFieldColorClass = 'add-custom-color-grey'
              }
            }
          })
        },
        deep: true,
      },
    },

    mounted () {
      api.getCities().then(response => {
        this.citiesRes = response.data.filter(data => data.gemeinde !== '')
        this.selectedCity = this.citiesRes[0]
      })

      api.fetchUpdate().then(response => {
        /**
         * Get response from fetchUpdate function in api.js
         * @returns update statistics for all cards
         */
        this.updateRes = response
        this.loading = false
      })

      api.allDocs().then(response => {
        /**
         * Get response from allDocs function in api.js
         * @returns update data for Extracted Table
         */
        this.allDocsData = response
        this.tableLoading = false
      })

      api.getfailedDocs().then(response => {
        /**
         * Get response from getfailedDocs function in api.js
         * @returns update data for Failed Table
         */
        this.failedDocsData = response
        console.log('Failed Data: ', response)
      })

      setTimeout(function () {
        /**
         * Scrolls down to Upload Document field
         */
        goTo('#uploadDocCard', { duration: 1500 })
      }, 2000)
    },

    created () {
      /**
       * consoles log either portal is running for 'Production mode' or for 'Development mode'
       */
      // this.InitAllDocsTable()
      console.log(process.env.NODE_ENV)
    },

    methods: {
      changeTab () {
        /**
         * Change the active tab to 'Failed Table' onclicking the Failed Card
         */
        this.activeTab = 'tab-2'
        this.PDFData = null
        this.selectedFile = []
        setTimeout(function () {
          goTo('#Documents', { duration: 1500 })
        })
      },
      tabExtracted () {
        /**
         * Update activeTab back as 'tab-1' on clicking Extarcted Tab
         * Close the PDF viewer and unselect selected docs to delete if selected
         */
        this.activeTab = 'tab-1'
        this.PDFData = undefined
        this.selectedFile = []
        this.activeItems = this.activeItemsExtracted
      },
      tabFailed () {
        /**
         * Close the PDF viewer and unselect selected docs to delete if selected
         */
        this.PDFData = undefined
        this.selectedFile = []
        this.activeTab = 'tab-2'
        this.activeItems = this.activeItemsFailed
      },
      closePdf () {
        /**
         * Close the PDF field
         */
        this.PDFData = undefined
      },
      dialogDeleteOpen () {
        /**
         * Open the Delete Dialog for single/multiple files
         */
        this.dialogDelete = true
        this.PDFData = undefined
      },
      dialogDeleteClose () {
        /**
         * Close the Delete Dialog for single/multiple files
         */
        this.dialogDelete = false
        this.selectedFile = []
      },
      setFailedDocName () {
        if (this.failedDocCheckbox) {
          this.failedDocCheckbox = false
        } else {
          this.failedDocCheckbox = true
        }

        if (this.failedDocCheckbox === true) {
          this.extractorRes.badExtractions = true
          this.activeDocName = 'Failed_' + this.activeDocName
        } else {
          this.activeDocName = this.tempDocName
          this.extractorRes.badExtractions = false
        }
      },
      paginations (pagination) {
        this.pagination = pagination
      },
      itemPerPage (itemPerPage) {
        this.itemsPerPage = itemPerPage
      },
      setItems (items) {
        if (this.activeTab === 'tab-1') {
          this.activeItems = items
          this.activeItemsExtracted = items
        }
      },
      setItemsFailed (items) {
        if (this.activeTab === 'tab-2') {
          this.activeItems = items
          this.activeItemsFailed = items
        }
      },

      InitAllDocsTable () {
        /**
         * Fill table with dummy data
         */
        this.allDocsData = [
          {
            time: Date.now(),
            guid: 'sampleGUID82138sadasb',
            fileName: 'sampleFile.pdf',
            barcode: '1238913821',
            validated: 'false',
            exported: 'false',
            documentClass: 'Report',
            detectedLanguage: 'English',
          },
        ]
        this.tableLoading = false
      },

      uploadFile (files) {
        /**
         * Uploads file/files
         * @param files
         * @returns Uploads the file/files on server
         */
        this.uploadTime = null
        this.ocrTime = null
        this.identifiedTime = null

        const StartTime = new Date()

        if (files.length < 1) {
          console.info('No file added')
          return
        }
        this.progressBar = true
        this.PDFData = null
        this.fileUploadLoading = true
        this.OCRloading = true
        this.Identifiedloading = true
        this.Extractedloading = true
        this.Failedloading = true

        const prevDocCount = this.allDocsData.length
        const prevUpload = this.updateRes.Uploaded
        const prevOCR = this.updateRes.OCRed
        const prevIdentified = this.updateRes.Identified
        const prevFailed = this.updateRes.Failed
        this.fileLength = this.fileLength + files.length

        if (this.uploadProgressbar === 100) {
          this.fileCounter = 0
          this.uploadProgressbar = 0
          this.uploadFileLength = 0
        }

        this.uploadFileLength = this.uploadFileLength + files.length

        files.map(file => {
          /**
           * Calls UploadFile function in api.js
           * @returns upload file on server
           */
          api.uploadFile(file, this.selectedCity)
            .then(response => {
              console.log(response)

              if (response) {
                this.fileCounter = this.fileCounter + 1

                this.uploadProgressbar = Math.round((this.fileCounter / this.uploadFileLength) * 100)
              }
            })
            .catch(err => {
              console.log(err.message)
              if (callUpdates) {
                this.fileUploaderModel = null
                clearInterval(callUpdates)
              }
            })
        })

        /**
         * Fetch Updates for Stats Cards, Extracted Table and Failed Table after Uploading file/files
         */
        const callUpdates = setInterval(() => {
          api.fetchUpdate()
            .then(response => {
              this.updateRes = response
            })
            .catch(err => {
              console.log(err.message)
              if (callUpdates) {
                this.fileUploaderModel = null
                clearInterval(callUpdates)
              }
            })

          api.allDocs()
            .then(response => {
              console.log('Extracted Data: ', response)
              this.allDocsData = response
            })
            .catch(err => {
              console.log(err.message)
              if (callUpdates) {
                this.fileUploaderModel = null
                clearInterval(callUpdates)
              }
            })

          api.getfailedDocs()
            .then(response => {
              console.log('Failed Data: ', response)
              this.failedDocsData = response
            })
            .catch(err => {
              console.log(err.message)
              if (callUpdates) {
                this.fileUploaderModel = null
                clearInterval(callUpdates)
              }
            })

          const currentIdentified = this.updateRes.Identified
          const currentOCR = this.updateRes.OCRed
          const currentUpload = this.updateRes.Uploaded

          if (prevUpload + this.uploadFileLength === currentUpload) {
            this.fileUploadLoading = false
            if (!this.uploadTime) {
              this.uploadTime = new Date()
            }
          }
          if (prevOCR + this.fileLength === currentOCR) {
            this.OCRloading = false
            if (!this.ocrTime) {
              this.ocrTime = new Date()
            }
          }
          if (prevIdentified + this.fileLength === currentIdentified) {
            this.Identifiedloading = false
            if (!this.identifiedTime) {
              this.identifiedTime = new Date()
            }
          }

          const absDocCount = Math.abs(prevDocCount - this.allDocsData.length)
          const absFailCount = Math.abs(prevFailed - this.updateRes.Failed)

          if (absDocCount + absFailCount === this.fileLength) {
            console.info('Clearing interval')
            clearInterval(callUpdates)
            goTo('#allDocTable', { duration: 1500 })
            this.fileUploadedSnack = true
            this.fileUploaderModel = null

            this.fileUploadLoading = false
            this.Identifiedloading = false
            this.OCRloading = false
            this.Extractedloading = false
            this.Failedloading = false
            this.fileCounter = 0
            this.uploadProgressbar = 0
            this.uploadFileLength = 0
            this.fileLength = 0
            this.progressBar = false

            const EndTime = new Date()

            console.log('[ Task Start->', StartTime, ']')
            console.log('Uploaded in ', (this.uploadTime.getTime() - StartTime.getTime()) / 1000, 'seconds')
            console.log('OCRed in ', (this.ocrTime.getTime() - StartTime.getTime()) / 1000, 'seconds')
            console.log('Identified in ', (this.identifiedTime.getTime() - StartTime.getTime()) / 1000, 'seconds')
            console.log('Extracted in ', (EndTime.getTime() - StartTime.getTime()) / 1000, 'seconds')
            console.log('Task completed in', (EndTime.getTime() - StartTime.getTime()) / 1000, 'seconds')
            console.log('[ Task End->', EndTime, ']')
          }
        }, 1500)
      },

      async loadPDF (pdfURL, extraction) {
        /**
         * Load the PDF URL to display in Extracted field
         * @param pdfURL
         * @param extraction
         * @returns shows PDF in PDF viewer and draws color rectangle to highlight OCR extracted key values
         */
        // const existingPdfBytes = await fetch('./Lucka_Badergasse_13_6090447.pdf')
        const existingPdfBytes = await fetch(pdfURL)
          .then(res => res.arrayBuffer())
          .catch(err => { console.error(err.message) })

        if (!existingPdfBytes) {
          this.genericError = true
          return
        }

        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pages = pdfDoc.getPages()

        // extraction = extraction.fields.filter(field => JSON.parse(field.jsonString).page_no)
        extraction = extraction.fields.filter(field => field.jsonString === '' ? null : JSON.parse(field.jsonString).page_no)

        // mapping all fields
        extraction.map(field => {
          if (!field.jsonString) {
            return
          }

          const color = this.validationKeys
            .reduce((result, item) => {
              // remove classifier from key as not needed here
              let subKey = field.key.replace(/druckprotocole_|Betriebsinformation_|NoClass_/gi, '')
              subKey = subKey === 'Nr.:' ? 'Haus-Nr.:' : subKey

              if (item.key === subKey) {
                result = item.pdfColor
              }
              return result
            }, null)

          if (!color) {
            return
          }

          const pageDetails = JSON.parse(field.jsonString)
          // getting page number (-1 because pages[] starts from 0)
          const extPageNum = parseInt(pageDetails.page_no) - 1

          let currPage = ''
          try {
            currPage = pages[extPageNum]
          } catch (error) {
            console.log('page number: ', extPageNum)
            console.error(error)
          }
          // get page width and height
          const { width, height } = currPage.getSize()

          // getting dimensions multiplier to trace api output here
          const widthMul = width / pageDetails.pageWidth
          const heightMul = height / pageDetails.pageHeight

          // determining values
          const newX = field.x * widthMul
          const newY = (height - (field.y * heightMul)) - (field.height * heightMul)
          const newWidth = field.width * widthMul
          const newHeight = field.height * heightMul
          // determining x and y and new width and height

          currPage.drawRectangle({
            x: newX,
            // subtract y from height and then from field height
            y: newY,
            width: newWidth,
            height: newHeight,
            opacity: 0.45,
            color: rgb(color.red, color.green, color.blue),
          })
        })

        const pdfBase64 = await pdfDoc.saveAsBase64()

        this.PDFData = pdfBase64
      },

      loadDisabledKeys () {
        /**
         * Load disabled keys incase validation not possible
         */
        const cleanedResponse = {}
        cleanedResponse.fields = [...this.validationKeys]

        // delete all keys, add equipment number again

        // further processing, only showing equipment number that has value
        const equipmentKeys = ['Equipment-Nr.:', 'Straßenakte:', 'Anlagenakte:', 'Anschluß-Nr.:']

        // select equipment number that is not empty
        const selEquipment = cleanedResponse.fields.filter(field => {
          return equipmentKeys.includes(field.key) && (field.value.druckVal !== '' || field.value.BetriVal !== '')
        })
        // remove all equipment numbers
        cleanedResponse.fields = cleanedResponse.fields.filter(field => {
          return !equipmentKeys.includes(field.key)
        })
        // push non empty equipment number to the start of the array
        if (selEquipment.length > 0) {
          selEquipment.map(equip => cleanedResponse.fields.unshift(equip))
        } else {
          // if equipment numbers empty then add equipment num by default
          cleanedResponse.fields.unshift({
            key: 'Equipment-Nr.:',
            id: 'equipNum',
            color: 'rgba(38, 70, 83, 0.45)',
            pdfColor: {
              red: 0.149,
              green: 0.274,
              blue: 0.325,
            },
            value: {
              druckVal: '',
              BetriVal: '',
              NoClassVal: '',
            },
            edited: {
              druckEdit: false,
              BetriEdit: false,
              NoClassEdit: false,
            },
            matches: {
              druckMatches: '',
              BetriMatches: '',
              NoClassMatches: '',
            },
            closest_matches: {
              druckClosestMatch: '',
              BetriClosestMatch: '',
              NoClassClosestMatch: '',
            },
            textFieldColorClass: '',
          })
        }
        this.extractorRes = cleanedResponse
        this.disabledRes = true
      },

      refreshKeys () {
        /**
         * Clear all validation keys, before loading the document
         */
        this.validationKeys.forEach(key => {
          key.value.druckVal = ''
          key.value.BetriVal = ''
          key.matches.druckMatches = ''
          key.matches.BetriMatches = ''
          key.closest_matches.druckClosestMatch = ''
          key.closest_matches.BetriClosestMatch = ''
        })
      },

      async loadGUID (guid, validated, fileName, id, index) {
        /**
         * Load specific doc to the pdf viewer and show it's extracted fields
         * @param guid get GUID of that specific document
         * @param validated boolean variable to check if doc is validated or not
         * @param fileName get file name of that document
         * @param id get id of that document
         * @returns PDF file and it's Extracted data
         */

        // Store information to reload the document.
        this.tempguid = guid;
        this.tempvalidated = validated;
        this.tempfileName = fileName;
        this.tempid = id;
        this.tempindex = index;

        this.isFirstPDF = false
        this.isLastPDF = false
        this.currentIndex = index
        this.oldequipBetri = ''
        this.selEquipBetri = ''
        this.oldAnlagneDruck = ''
        this.selAnlagneDruck = ''

        this.disabledRes = false
        // clear all validation keys values
        this.refreshKeys()
        this.activeDocName = fileName
        this.tempDocName = fileName
        this.chipsData = []
        this.failedDocCheckbox = false

        if (this.pagination === 1 && this.currentIndex === 0) {
          this.isFirstPDF = true
        }
        if (this.pagination === this.totalPages && this.activeItems.length === this.currentIndex + 1) {
          this.isLastPDF = true
        }

        await api.getDocId(guid)
          .then(response => {
            /**
             * Call getDocId function in api.js
             * @returns specific document URL
             */
            this.activeDocURL = response
          })
          .catch(error => {
            console.log(error)
            this.prevDocBtnLoader = false
            this.nextDocBtnLoader = false
          })

        // if loading failed document then
        // no not call extraction api
        // if (failed) {
        //   this.PDFData = this.activeDocURL
        //   return
        // }

        await api.getDocExt(guid)
          .then(async response => {
            debugger;
            /**
             * Call getDocExt function in api.js
             * @param guid document GUID to be extracted
             * @returns get specific document extraction using GUID
             */
            if (response.isAxiosError) {
              this.PDFData = this.activeDocURL
              this.activeGUID = guid
              this.loadDisabledKeys()
              this.prevDocBtnLoader = false
              this.nextDocBtnLoader = false
              return
            }
            // await this.loadPDF(this.activeDocURL, response)
            if (process.env.NODE_ENV === 'production') {
              await this.loadPDF(this.activeDocURL, response)
            } else {
              this.PDFData = this.activeDocURL
            }

            if (response.fields.length === 0) {
              this.disabledRes = true
            }

            // set current doc id
            this.currDocID = id
            // this.isFirstPDF = (id === 1)
            // this.isLastPDF = (id === this.allDocsData.length)
            this.prevDocBtnLoader = false
            this.nextDocBtnLoader = false

            // scroll down
            setTimeout(function () {
              goTo('#detailsCard', { duration: 1500 })
            }, 1000)

            const cleanedResponse = {}
            // setting type and region
            cleanedResponse.docType = response.type
            response.language.includes('German') ? cleanedResponse.docRegion = 'Germany' : cleanedResponse.docRegion = 'Unknown'

            // cleanedResponse.docConfidence = {}
            // cleanedResponse.docConfidence.score = (response.docConfidence * 100).toFixed(2)
            // if (cleanedResponse.docConfidence.score) {
            //   if (cleanedResponse.docConfidence.score >= 0 && cleanedResponse.docConfidence.score <= 30) {
            //     cleanedResponse.docConfidence.barcolor = 'red'
            //   } else if (cleanedResponse.docConfidence.score >= 31 && cleanedResponse.docConfidence.score <= 61) {
            //     cleanedResponse.docConfidence.barcolor = 'orange'
            //   } else if (cleanedResponse.docConfidence.score >= 60 && cleanedResponse.docConfidence.score <= 100) {
            //     cleanedResponse.docConfidence.barcolor = 'green'
            //   }
            // }

            cleanedResponse.badExtractions = response.badExtractions
            if (cleanedResponse.badExtractions === 'True') {
              this.failedDocCheckbox = true
            }

            cleanedResponse.featureInstanz = {}
            let featInst = response.fields.find(el => el.key === 'Betriebsinformation_Feature-Instanz')
            if (featInst) {
              cleanedResponse.featureInstanz.BetriVal = featInst.value
            }
            featInst = response.fields.find(el => el.key === 'druckprotocole_Feature-Instanz')
            if (featInst) {
              cleanedResponse.featureInstanz.druckVal = featInst.value
            }
            featInst = response.fields.find(el => el.key === 'NoClass_Feature-Instanz')
            if (featInst) {
              cleanedResponse.featureInstanz.NoClassVal = featInst.value
            }

            cleanedResponse.postleizahl = {}
            let postleizahl = response.fields.find(el => el.key === 'Betriebsinformation_Postleitzahl')
            if (postleizahl) {
              cleanedResponse.postleizahl.BetriVal = postleizahl.value
            }
            postleizahl = response.fields.find(el => el.key === 'druckprotocole_Postleitzahl')
            if (postleizahl) {
              cleanedResponse.postleizahl.druckVal = postleizahl.value
            }
            postleizahl = response.fields.find(el => el.key === 'NoClass_Postleitzahl')
            if (postleizahl) {
              cleanedResponse.postleizahl.NoClassVal = postleizahl.value
            }

            // check if Betri page exists
            const betriPage = response.fields
              .find(field => field.key.includes('Betriebsinformation_'))

            // check if Druck page exists
            const DruckPage = response.fields
              .find(field => field.key.includes('druckprotocole_'))

            // check if NoClass page exists
            const noClass = response.fields
              .find(field => field.key.includes('NoClass_'))

            /**
             * Extract relevant responses
             */
            this.validationKeys.map((item) => {
              let noClassSelVal = null
              if (noClass) {
                // getting NoClass value
                if (item.key === 'Ort:') {
                  noClassSelVal = response.fields.find(
                    (field) => field.key === 'NoClass_Gemeinde:',
                  )
                  if (!noClassSelVal.value) {
                    // if geneinde is empty then pick ortsnetz values
                    noClassSelVal = response.fields.find(
                      (field) => field.key === 'NoClass_Ortsnetz:',
                    )
                  }
                } else {
                  noClassSelVal = response.fields.find(
                    (field) => field.key === 'NoClass_' + item.key,
                  )
                }
              }

              if (noClassSelVal) {
                if (noClassSelVal.editStatus) {
                  item.value.NoClassVal = noClassSelVal.editedValue ? noClassSelVal.editedValue : noClassSelVal.value
                  item.edited.NoClassEdit = true
                } else {
                  item.value.NoClassVal = noClassSelVal.value
                  item.edited.NoClassEdit = false
                }
                // extracting non empty matches for NoClass
                if (
                  noClassSelVal.jsonString &&
                  JSON.parse(noClassSelVal.jsonString).closest_matches
                ) {
                  item.matches.NoClassMatches = noClassSelVal.jsonString
                }
              }

              let BetriSelVal = null
              if (betriPage) {
                // getting Betriebsinformation value
                if (item.key === 'Ort:') {
                  BetriSelVal = response.fields.find(
                    (field) => field.key === 'Betriebsinformation_Gemeinde:',
                  )
                  if (!BetriSelVal.value) {
                    // if geneinde is empty then pick ortsnetz values
                    BetriSelVal = response.fields.find(
                      (field) => field.key === 'Betriebsinformation_Ortsnetz:',
                    )
                  }
                } else {
                  BetriSelVal = response.fields.find(
                    (field) => field.key === 'Betriebsinformation_' + item.key,
                  )
                }
              }

              if (BetriSelVal) {
                if (BetriSelVal.editStatus) {
                  item.value.BetriVal = BetriSelVal.editedValue ? BetriSelVal.editedValue : BetriSelVal.value
                  item.edited.BetriEdit = true
                } else {
                  item.value.BetriVal = BetriSelVal.value
                  item.edited.BetriEdit = false
                }
                // extracting non empty matches for betrieb
                if (
                  BetriSelVal.jsonString &&
                  JSON.parse(BetriSelVal.jsonString).closest_matches
                ) {
                  item.matches.BetriMatches = BetriSelVal.jsonString
                }
              }

              // getting druckprotocole value
              let druckSelVal = null
              if (DruckPage) {
                if (item.key === 'Ort:' || item.key === 'Ortsteil:') {
                  druckSelVal = response.fields.find(
                    (field) => field.key === 'druckprotocole_Gemeinde:',
                  )
                  if (!druckSelVal.value) {
                    // if geneinde is empty then pick ortsnetz values
                    druckSelVal = response.fields.find(
                      (field) => field.key === 'druckprotocole_Ortsnetz:',
                    )
                  }
                } else if (item.key === 'Haus-Nr.:') {
                  druckSelVal = response.fields.find(
                    (field) => field.key === 'druckprotocole_Nr.:',
                  )
                } else {
                  druckSelVal = response.fields.find(
                    (field) => field.key === 'druckprotocole_' + item.key,
                  )
                }
              }

              if (druckSelVal) {
                if (druckSelVal.editStatus) {
                  item.value.druckVal = druckSelVal.editedValue ? druckSelVal.editedValue : druckSelVal.value
                  item.edited.druckEdit = true
                } else {
                  item.value.druckVal = druckSelVal.value
                  item.edited.druckEdit = false
                }
                // extracting non empty matches for druck
                if (
                  druckSelVal.jsonString &&
                  JSON.parse(druckSelVal.jsonString).closest_matches
                ) {
                  item.matches.druckMatches = druckSelVal.jsonString
                }
              }

              // if item has a NoClass match then store more granular data
              if (item.matches.NoClassMatches) {
                // item.closest_matches = []
                item.closest_matches.NoClassClosestMatch = JSON.parse(
                  item.matches.NoClassMatches,
                ).closest_matches

                item.closest_matches.NoClassClosestMatch = item.closest_matches.NoClassClosestMatch.map(
                  (match, index) => {
                    return { value: match[0], score: match[1], type: 'match', id: index }
                  },
                )
                item.closest_matches.NoClassClosestMatch.push({
                  value: JSON.parse(item.matches.NoClassMatches).model_string,
                  type: 'model_string',
                  id: item.closest_matches.NoClassClosestMatch.length,
                })
                // item.model_string = JSON.parse(item.matches).model_string
              }

              // if item has a druck match then store more granular data
              if (item.matches.druckMatches) {
                // item.closest_matches = []
                item.closest_matches.druckClosestMatch = JSON.parse(
                  item.matches.druckMatches,
                ).closest_matches

                item.closest_matches.druckClosestMatch = item.closest_matches.druckClosestMatch.map(
                  (match, index) => {
                    return { value: match[0], score: match[1], type: 'match', id: index }
                  },
                )
                item.closest_matches.druckClosestMatch.push({
                  value: JSON.parse(item.matches.druckMatches).model_string,
                  type: 'model_string',
                  id: item.closest_matches.druckClosestMatch.length + 1,
                })
                // item.model_string = JSON.parse(item.matches).model_string
              }

              // if item has a betrieb match then store more granular data
              if (item.matches.BetriMatches) {
                // item.closest_matches = []
                item.closest_matches.BetriClosestMatch = JSON.parse(
                  item.matches.BetriMatches,
                ).closest_matches

                item.closest_matches.BetriClosestMatch = item.closest_matches.BetriClosestMatch.map(
                  (match, index) => {
                    return { value: match[0], score: match[1], type: 'match', id: index }
                  },
                )
                item.closest_matches.BetriClosestMatch.push({
                  value: JSON.parse(item.matches.BetriMatches).model_string,
                  type: 'model_string',
                  id: item.closest_matches.BetriClosestMatch.length,
                })
                // item.model_string = JSON.parse(item.matches).model_string
              }
              // deleting item.matches causes it to fail
              // delete item.matches
            })

            // cleanedResponse.fields = [...this.validationKeys]
            // creating a deep copy of the array
            cleanedResponse.fields = JSON.parse(JSON.stringify(this.validationKeys))

            // further processing, only showing equipment number that has value
            const equipmentKeys = ['Equipment-Nr.:', 'Straßenakte:', 'Anlagenakte:', 'Anschluß-Nr.:']

            // retriving equipmentno that is present in for betri
            const betriEquip = response.fields.filter(field => {
              return field.key === 'Betriebsinformation_Equipment-Nr.:' || field.key === 'Betriebsinformation_Straßenakte:' || field.key === 'Betriebsinformation_Anlagenakte:' || field.key === 'Betriebsinformation_Anschluß-Nr.:'
            })

            // retriving equipmentno that is present in for druck
            const druckEquip = response.fields.filter(field => {
              return field.key === 'druckprotocole_Equipment-Nr.:' || field.key === 'druckprotocole_Straßenakte:' || field.key === 'druckprotocole_Anlagenakte:' || field.key === 'druckprotocole_Anschluß-Nr.:'
            })

            // retriving equipmentno that is present in for NoClass
            const NoClassEquip = response.fields.filter(field => {
              return field.key === 'NoClass_Equipment-Nr.:' || field.key === 'NoClass_Straßenakte:' || field.key === 'NoClass_Anlagenakte:' || field.key === 'NoClass_Anschluß-Nr.:'
            })

            let finalEquip = betriEquip.concat(druckEquip)
            finalEquip = finalEquip.concat(NoClassEquip)

            // select equip that is present in response and in cleanedResponse
            const selEquipment = cleanedResponse.fields.filter(field => {
              const keyFound = finalEquip.find(data => data.key === 'Betriebsinformation_' + field.key || data.key === 'druckprotocole_' + field.key || data.key === 'NoClass_' + field.key)
              if (keyFound) return field
            })

            // remove all equipment numbers
            cleanedResponse.fields = cleanedResponse.fields.filter(field => {
              return !equipmentKeys.includes(field.key)
            })

            // push non empty equipment number to the start of the array
            if (selEquipment.length > 0) {
              selEquipment.map(equip => {
                cleanedResponse.fields.unshift(equip)
              })
            } else {
              // if equipment numbers empty then add equipment num by default
              if (betriPage || noClass) {
                cleanedResponse.fields.unshift({
                  key: 'Equipment-Nr.:',
                  id: 'equipNum',
                  color: 'rgba(38, 70, 83, 0.45)',
                  pdfColor: {
                    red: 0.149,
                    green: 0.274,
                    blue: 0.325,
                  },
                  value: {
                    druckVal: '',
                    BetriVal: '',
                    NoClassVal: '',
                  },
                  edited: {
                    druckEdit: false,
                    BetriEdit: false,
                    NoClassEdit: false,
                  },
                  matches: {
                    druckMatches: '',
                    BetriMatches: '',
                    NoClassMatches: '',
                  },
                  closest_matches: {
                    druckClosestMatch: '',
                    BetriClosestMatch: '',
                    NoClassClosestMatch: '',
                  },
                  textFieldColorClass: '',
                })
              }

              if (DruckPage) {
                cleanedResponse.fields.unshift({
                  key: 'Anlagenakte:',
                  id: 'anlagenakte',
                  color: 'rgba(38, 70, 83, 0.45)',
                  pdfColor: {
                    red: 0.149,
                    green: 0.274,
                    blue: 0.325,
                  },
                  value: {
                    druckVal: '',
                    BetriVal: '',
                    NoClassVal: '',
                  },
                  edited: {
                    druckEdit: false,
                    BetriEdit: false,
                    NoClassEdit: false,
                  },
                  matches: {
                    druckMatches: '',
                    BetriMatches: '',
                    NoClassMatches: '',
                  },
                  closest_matches: {
                    druckClosestMatch: '',
                    BetriClosestMatch: '',
                    NoClassClosestMatch: '',
                  },
                  textFieldColorClass: '',
                })
              }
            }

            // getting page number of keys
            const BetriPage = response.fields
              .find(field => field.key.includes('Betriebsinformation_'))
            if (BetriPage && BetriPage.jsonString) {
              const fieldDetails = JSON.parse(BetriPage.jsonString)
              // if exists
              cleanedResponse.BetriPage = fieldDetails.page_no ? fieldDetails.page_no : 1
            } else {
              cleanedResponse.BetriPage = null
            }

            const druckPage = response.fields
              .find(field => field.key.includes('druckprotocole_'))
            if (druckPage && druckPage.jsonString) {
              const fieldDetails = JSON.parse(druckPage.jsonString)
              // if exists
              cleanedResponse.druckPage = fieldDetails.page_no ? fieldDetails.page_no : 1
            } else {
              cleanedResponse.druckPage = null
            }

            const NoClassPage = response.fields
              .find(field => field.key.includes('NoClass_'))
            if (NoClassPage && NoClassPage.jsonString) {
              const fieldDetails = JSON.parse(NoClassPage.jsonString)
              // if exists
              cleanedResponse.NoClassPage = fieldDetails.page_no ? fieldDetails.page_no : 1
            } else {
              cleanedResponse.NoClassPage = null
            }

            this.extractorRes = cleanedResponse
            this.activeGUID = guid
            // checking if clicked doc is already validated
            console.log(cleanedResponse)
            this.validationTab = 'BetriTab'

            // deleting Gemiende and Ortsnetz
            const index = this.extractorRes.fields.findIndex(field => field.key === 'Gemeinde:')
            this.extractorRes.fields.splice(index, 2)

            // assign chips values from extraction response
            this.getChipsData(response)
          })
        if (validated === 'Yes') {
          this.setDocName()
        } else {
          this.currValidated = false
        }
      },

      getUpdatedFieldValues (response) {
        this.extractorRes.fields.map((item) => {
          let noClassSelVal = null
          if (this.extractorRes.NoClassPage) {
            // getting NoClass value
            if (item.key === 'Ort:') {
              noClassSelVal = response.fields.find(
                (field) => field.key === 'NoClass_Gemeinde:',
              )
              if (noClassSelVal.jsonString && JSON.parse(noClassSelVal.jsonString).closest_matches === null) {
                // if geneinde is empty then pick ortsnetz values
                noClassSelVal = response.fields.find(
                  (field) => field.key === 'NoClass_Ortsnetz:',
                )
              }
            } else if (item.key === 'Straße:') {
              noClassSelVal = response.fields.find(
                (field) => field.key === 'NoClass_Straße:',
              )
            }
          }

          if (noClassSelVal) {
            if (noClassSelVal.editedValue) {
              item.value.NoClassVal = noClassSelVal.editedValue
              item.edited.NoClassEdit = true
            } else {
              item.value.NoClassVal = noClassSelVal.value
              item.edited.NoClassEdit = false
            }
            // extracting non empty matches for NoClass
            if (
              noClassSelVal.jsonString &&
              JSON.parse(noClassSelVal.jsonString).closest_matches
            ) {
              item.matches.NoClassMatches = noClassSelVal.jsonString
            }
          }

          let BetriSelVal = null
          if (this.extractorRes.BetriPage) {
            // getting Betriebsinformation value
            if (item.key === 'Ort:') {
              BetriSelVal = response.fields.find(
                (field) => field.key === 'Betriebsinformation_Gemeinde:',
              )
              if (BetriSelVal.jsonString && JSON.parse(BetriSelVal.jsonString).closest_matches === null) {
                // if geneinde is empty then pick ortsnetz values
                BetriSelVal = response.fields.find(
                  (field) => field.key === 'Betriebsinformation_Ortsnetz:',
                )
              }
            } else if (item.key === 'Straße:') {
              BetriSelVal = response.fields.find(
                (field) => field.key === 'Betriebsinformation_Straße:',
              )
            }
          }

          if (BetriSelVal) {
            if (BetriSelVal.editedValue) {
              item.value.BetriVal = BetriSelVal.editedValue
              item.edited.BetriEdit = true
            } else {
              item.value.BetriVal = BetriSelVal.value
              item.edited.BetriEdit = false
            }
            // extracting non empty matches for betrieb
            if (
              BetriSelVal.jsonString &&
              JSON.parse(BetriSelVal.jsonString).closest_matches
            ) {
              item.matches.BetriMatches = BetriSelVal.jsonString
            }
          }

          // getting druckprotocole value
          let druckSelVal = null
          if (this.extractorRes.druckPage) {
            if (item.key === 'Ort:') {
              druckSelVal = response.fields.find(
                (field) => field.key === 'druckprotocole_Gemeinde:',
              )
              if (druckSelVal.jsonString && JSON.parse(druckSelVal.jsonString).closest_matches === null) {
                // if geneinde is empty then pick ortsnetz values
                druckSelVal = response.fields.find(
                  (field) => field.key === 'druckprotocole_Ortsnetz:',
                )
              }
            } else if (item.key === 'Straße:') {
              druckSelVal = response.fields.find(
                (field) => field.key === 'druckprotocole_Straße:',
              )
            }
          }

          if (druckSelVal) {
            if (druckSelVal.editedValue) {
              item.value.druckVal = druckSelVal.editedValue
              item.edited.druckEdit = true
            } else {
              item.value.druckVal = druckSelVal.value
              item.edited.druckEdit = false
            }
            // extracting non empty matches for druck
            if (
              druckSelVal.jsonString &&
              JSON.parse(druckSelVal.jsonString).closest_matches
            ) {
              item.matches.druckMatches = druckSelVal.jsonString
            }
          }

          // if item has a NoClass match then store more granular data
          if (item.matches.NoClassMatches) {
            // item.closest_matches = []
            item.closest_matches.NoClassClosestMatch = JSON.parse(
              item.matches.NoClassMatches,
            ).closest_matches

            item.closest_matches.NoClassClosestMatch = item.closest_matches.NoClassClosestMatch.map(
              (match, index) => {
                return { value: match[0], score: match[1], type: 'match', id: index }
              },
            )
            item.closest_matches.NoClassClosestMatch.push({
              value: JSON.parse(item.matches.NoClassMatches).model_string,
              type: 'model_string',
              id: item.closest_matches.NoClassClosestMatch.length,
            })
            // item.model_string = JSON.parse(item.matches).model_string
          }

          // if item has a druck match then store more granular data
          if (item.matches.druckMatches) {
            // item.closest_matches = []
            item.closest_matches.druckClosestMatch = JSON.parse(
              item.matches.druckMatches,
            ).closest_matches

            item.closest_matches.druckClosestMatch = item.closest_matches.druckClosestMatch.map(
              (match, index) => {
                return { value: match[0], score: match[1], type: 'match', id: index }
              },
            )
            item.closest_matches.druckClosestMatch.push({
              value: JSON.parse(item.matches.druckMatches).model_string,
              type: 'model_string',
              id: item.closest_matches.druckClosestMatch.length + 1,
            })
            // item.model_string = JSON.parse(item.matches).model_string
          }

          // if item has a betrieb match then store more granular data
          if (item.matches.BetriMatches) {
            // item.closest_matches = []
            item.closest_matches.BetriClosestMatch = JSON.parse(
              item.matches.BetriMatches,
            ).closest_matches

            item.closest_matches.BetriClosestMatch = item.closest_matches.BetriClosestMatch.map(
              (match, index) => {
                return { value: match[0], score: match[1], type: 'match', id: index }
              },
            )
            item.closest_matches.BetriClosestMatch.push({
              value: JSON.parse(item.matches.BetriMatches).model_string,
              type: 'model_string',
              id: item.closest_matches.BetriClosestMatch.length,
            })
            // item.model_string = JSON.parse(item.matches).model_string
          }
          // deleting item.matches causes it to fail
          // delete item.matches
        })
      },

      getChipsData (response) {
        this.chipsData = []
        const tempOrtGemeinde = response.fields.find(el => el.key === 'DB_Gemeinde:')
        const tempOrtOrtnetz = response.fields.find(el => el.key === 'DB_Ortsnetz:')
        if (tempOrtGemeinde) {
          this.chipsData.push({
            key: tempOrtGemeinde.key,
            color: 'rgba(231, 111, 81, 0.45)',
            value: tempOrtGemeinde.value,
          })
        } else if (tempOrtOrtnetz) {
          this.chipsData.push({
            key: tempOrtOrtnetz.key,
            color: 'rgba(231, 111, 81, 0.45)',
            value: tempOrtOrtnetz.value,
          })
        }

        const tempOrtsteil = response.fields.find(el => el.key === 'DB_Ortsteil:')
        if (tempOrtsteil) {
          this.chipsData.push({
            key: tempOrtsteil.key,
            color: 'rgba(244, 162, 97, 0.45)',
            value: tempOrtsteil.value,
          })
        }

        const tempPostleizahl = response.fields.find(el => el.key === 'DB_Postleitzahl')
        if (tempPostleizahl) {
          this.chipsData.push({
            key: tempPostleizahl.key,
            color: 'rgba(35, 104, 189, 0.45)',
            value: tempPostleizahl.value,
          })
        }

        const tempStrabe = response.fields.find(el => el.key === 'DB_Straße:')
        if (tempStrabe) {
          this.chipsData.push({
            key: tempStrabe.key,
            color: 'rgba(42, 157, 143, 0.45)',
            value: tempStrabe.value,
          })
        }

        const tempHausNr = response.fields.find(el => el.key === 'DB_Haus-Nr.:')
        if (tempHausNr) {
          this.chipsData.push({
            key: tempHausNr.key,
            color: 'rgba(233, 196, 106, 0.45)',
            value: tempHausNr.value,
          })
        }

        const tempFeature = response.fields.find(el => el.key === 'DB_Feature-Instanz')
        if (tempFeature) {
          this.chipsData.push({
            key: tempFeature.key,
            color: 'rgba(35, 104, 109, 0.45)',
            value: tempFeature.value,
          })
        }

        const tempEquip = response.fields.find(el => el.key === 'DB_Equipment-Nr.:')
        if (tempEquip) {
          this.chipsData.push({
            key: tempEquip.key,
            color: 'rgba(38, 70, 83, 0.45)',
            value: tempEquip.value,
          })
        }

        if (this.chipsData.length > 0) {
          const tempdoctype = response.type
          if (tempdoctype) {
            this.chipsData.push({
              key: 'doctype',
              value: tempdoctype,
              color: 'rgba(100, 19, 41, 0.4)',
            })
          }
        }
        // console.log('this is chips data', this.chipsData)

        this.chipsUpdateArray = response.fields.filter(field => {
          if (field.key.includes('DB_')) {
            return field
          }
        })
        console.log('chipsUpdateArray', this.chipsUpdateArray)
        console.log('chips', this.chipsData)
      },

      setDocName () {
        /**
         * Sets document name for download if document is validated
         * @returns Document name
         */

        this.currValidated = true
        setTimeout(() => {
          if (this.extractorRes.badExtractions === true || this.extractorRes.badExtractions === 'True') {
            this.DocValidName = this.activeDocName
          } else {
            let ortVal = ''
            let equipVal = ''
            let strabeVal = ''
            let housenumVal = ''
            let ortsteilVal = ''
            let doctype = ''
            let postleitzahlVal = ''

            let community = ''
            let street = ''
            let zipCode = ''
            let houseNum = ''
            let district = ''
            let equipNum = ''
            let featureInstanzVal = ''

            if (this.chipsData.length > 0) {
              this.chipsData.map((field) => {
                if (field.key === 'DB_Equipment-Nr.:') {
                  equipVal = field.value
                } else if (field.key === 'DB_Straße:') {
                  strabeVal = field.value
                } else if (field.key === 'DB_Haus-Nr.:') {
                  housenumVal = field.value
                } else if (field.key === 'DB_Ortsteil:') {
                  ortsteilVal = field.value
                } else if (field.key === 'DB_Feature-Instanz') {
                  featureInstanzVal = field.value
                } else if (field.key === 'DB_Postleitzahl') {
                  postleitzahlVal = field.value
                } else if (field.key === 'doctype') {
                  doctype = field.value
                } else if (field.key === 'DB_Gemeinde:') {
                  if (field.value) {
                    ortVal = field.value
                  } else {
                    const temp = this.chipsData.find(el => el.key === 'DB_Ortsnetz:')
                    ortVal = temp.value
                  }
                }
              })

              community = ortVal
              district = ortsteilVal
              zipCode = postleitzahlVal
              street = strabeVal
              houseNum = housenumVal
              equipNum = equipVal + '_' + doctype

              this.DocValidName = community + '_' + district + '_' + zipCode + '_' + street + '_' + houseNum + '_' + featureInstanzVal + '_' + equipNum + '.pdf'
              this.DocValidName = this.DocValidName.replace(/ /g, '_')
            } else {
              // assigning value
              this.extractorRes.fields.map((item) => {
                if (item.key === 'Equipment-Nr.:' || item.key === 'Straßenakte:' || item.key === 'Anlagenakte:' || item.key === 'Anschluß-Nr.:') {
                  equipVal = item
                } else if (item.key === 'Ort:') {
                  ortVal = item
                } else if (item.key === 'Straße:') {
                  strabeVal = item
                } else if (item.key === 'Haus-Nr.:') {
                  housenumVal = item
                } else if (item.key === 'Ortsteil:') {
                  ortsteilVal = item
                }
              })

              if (this.extractorRes.NoClassPage) {
                // For NoClass page

                // For Ort value
                if (ortVal.value.NoClassVal) {
                  if (typeof ortVal.value.NoClassVal === 'object') {
                    community = ortVal.value.NoClassVal.value
                  } else {
                    community = ortVal.value.NoClassVal
                  }
                }

                // For Strabe value
                if (typeof strabeVal.value.NoClassVal === 'object') {
                  street = strabeVal.value.NoClassVal.value.replace(/ /g, '-')
                } else {
                  street = strabeVal.value.NoClassVal.replace(/ /g, '-')
                }

                // For Hau-Nr value
                if (housenumVal.value.NoClassVal) {
                  houseNum = housenumVal.value.NoClassVal
                }

                // For Ortsteil
                if (ortsteilVal.value.NoClassVal) {
                  if (typeof ortsteilVal.value.NoClassVal === 'object') {
                    district = ortsteilVal.value.NoClassVal.value
                  } else {
                    district = ortsteilVal.value.NoClassVal
                  }
                }

                // For Feature-Instanz
                if (this.extractorRes.featureInstanz.NoClassVal) {
                  featureInstanzVal = this.extractorRes.featureInstanz.NoClassVal
                }

                // For Equipment-Nr and other values
                if (equipVal.value.NoClassVal) {
                  equipNum = equipVal.value.NoClassVal
                  if (this.extractorRes.NoClassPage) {
                    equipNum = equipNum + '_NC'
                  }
                }
              } else {
                // For Betri and Druck page

                // For Ort value
                if (ortVal.value.BetriVal || ortVal.value.druckVal) {
                  if (typeof ortVal.value.BetriVal === 'object' || typeof ortVal.value.druckVal === 'object') {
                    community = ortVal.value.BetriVal.value ? ortVal.value.BetriVal.value : ortVal.value.druckVal.value
                  } else {
                    community = ortVal.value.BetriVal ? ortVal.value.BetriVal : ortVal.value.druckVal
                  }
                }

                // For Strabe value
                if (typeof strabeVal.value.BetriVal === 'object' || typeof strabeVal.value.druckVal === 'object') {
                  street = strabeVal.value.BetriVal.value ? strabeVal.value.BetriVal.value.replace(/ /g, '-') : strabeVal.value.druckVal.value.replace(/ /g, '-')
                } else {
                  street = strabeVal.value.BetriVal ? strabeVal.value.BetriVal.replace(/ /g, '-') : strabeVal.value.druckVal.replace(/ /g, '-')
                }

                // For Hau-Nr value
                if (housenumVal.value.BetriVal || housenumVal.value.druckVal) {
                  houseNum = housenumVal.value.BetriVal ? housenumVal.value.BetriVal : housenumVal.value.druckVal
                }

                // For Ortsteil
                if (ortsteilVal.value.BetriVal || ortsteilVal.value.druckVal) {
                  if (typeof ortsteilVal.value.BetriVal === 'object' || typeof ortsteilVal.value.druckVal === 'object') {
                    district = ortsteilVal.value.BetriVal.value ? ortsteilVal.value.BetriVal.value : ortsteilVal.value.druckVal.value
                  } else {
                    district = ortsteilVal.value.BetriVal ? ortsteilVal.value.BetriVal : ortsteilVal.value.druckVal
                  }
                }

                // For Feature-Instanz
                if (this.extractorRes.featureInstanz.BetriVal) {
                  featureInstanzVal = this.extractorRes.featureInstanz.BetriVal
                } else if (this.extractorRes.featureInstanz.druckVal) {
                  featureInstanzVal = this.extractorRes.featureInstanz.druckVal
                }

                // For Equipment-Nr and other values
                if (equipVal.value.BetriVal || equipVal.value.druckVal) {
                  equipNum = equipVal.value.BetriVal ? equipVal.value.BetriVal : equipVal.value.druckVal
                  if (this.extractorRes.BetriPage && this.extractorRes.druckPage) {
                    equipNum = equipNum + '_BIS_DP'
                  } else if (this.extractorRes.BetriPage && !this.extractorRes.druckPage) {
                    equipNum = equipNum + '_BIS'
                  } else if (this.extractorRes.druckPage && !this.extractorRes.BetriPage) {
                    equipNum = equipNum + '_DP'
                  }
                }
              }
              // File Name
              if (!community && district && houseNum && featureInstanzVal) {
                this.DocValidName = district + '_' + street + '_' + houseNum + '_' + featureInstanzVal + '_' + equipNum + '.pdf'
              } else if (!district && community && houseNum && featureInstanzVal) {
                this.DocValidName = community + '_' + street + '_' + houseNum + '_' + featureInstanzVal + '_' + equipNum + '.pdf'
              } else if (!houseNum && community && district && featureInstanzVal) {
                this.DocValidName = community + '_' + district + '_' + street + '_' + featureInstanzVal + '_' + equipNum + '.pdf'
              } else if (!featureInstanzVal && community && district && houseNum) {
                this.DocValidName = community + '_' + district + '_' + street + '_' + houseNum + '_' + equipNum + '.pdf'
              } else if (!community && !district && !houseNum && !featureInstanzVal) {
                this.DocValidName = street + '_' + equipNum + '.pdf'
              } else if (!community && !featureInstanzVal && !houseNum && district) {
                this.DocValidName = district + '_' + street + '_' + equipNum + '.pdf'
              } else if (!district && !houseNum && !featureInstanzVal && community) {
                this.DocValidName = community + '_' + street + '_' + equipNum + '.pdf'
              } else if (!houseNum && !featureInstanzVal && community && district) {
                this.DocValidName = community + '_' + district + '_' + street + '_' + equipNum + '.pdf'
              } else if (!district && !featureInstanzVal && community && houseNum) {
                this.DocValidName = community + '_' + street + '_' + houseNum + '_' + equipNum + '.pdf'
              } else if (!community && !featureInstanzVal && district && houseNum) {
                this.DocValidName = district + '_' + street + '_' + houseNum + '_' + equipNum + '.pdf'
              } else {
                this.DocValidName = community + '_' + district + '_' + zipCode + '_' + street + '_' + houseNum + '_' + featureInstanzVal + '_' + equipNum + '.pdf'
              }

              this.DocValidName = this.DocValidName.replace(/ /g, '_')
            }
          }
        }, 500)
      },

      deleteFile () {
        /**
         * Function to delete a single or multiple file
         */
        this.dialogDelete = false
        this.loadDelFileAPI = true

        this.selectedFile.map(async (val) => {
          await api.getUserID(val)
            .then(response => {
              /**
               * Call getUserID function in api.js
               * @returns extracts UserID from response
               */
              this.UserID = response.userID
            })
            .catch(error => {
              console.log(error)
            })

          await api.deleteFile(val, this.UserID)
            .then(response => {
              /**
               * Call deleteFile function in api.js
               * @param guid GUID of document to be deleted
               * @param UserID UserID of document to be deleted
               * @returns delete the selected files
               */
              if (response && this.UserID !== undefined) {
                this.loadDelFileAPI = false
                this.deleteFileSnack = true
                api.fetchUpdate()
                  .then(response => {
                    this.updateRes = response
                  })
                api.allDocs()
                  .then(response => {
                    this.allDocsData = response
                  })
                api.getfailedDocs()
                  .then(response => {
                    this.failedDocsData = response
                  })
              } else {
                this.deleteFileFailedSnack = true
              }
            })
            .catch(error => {
              console.log(error)
            })
        })
        this.selectedFile = []
      },

      deleteDocs () {
        /**
         * Delete all data from the Stats cards, Extracted and Failed tables
         */
        this.loadClearAPI = true
        this.fileUploaderModel = null

        api.clearData()
          .then(response => {
            /**
             * Call clearData function in api.js
             * @returns deletes all data
             */
            this.loadClearAPI = false

            if (response) {
              this.clearDataRes = true

              this.fileUploaderModel = null
              this.fileUploadLoading = false
              this.Identifiedloading = false
              this.OCRloading = false
              this.Extractedloading = false
              this.Failedloading = false
              this.fileCounter = 0
              this.uploadProgressbar = 0
              this.uploadFileLength = 0
              this.fileLength = 0
              this.progressBar = false

              setTimeout(() => {
                this.clearDataDialog = false
                this.clearDataRes = undefined
              }, 1000)

              api.fetchUpdate().then(response => {
                /**
                 * Call fetchUpdate function in api.js
                 * @returns updates stats cards
                 */
                console.log(response)
                this.updateRes = response
              })
              api.allDocs().then(response => {
                /**
                 * Call allDocs function in api.js
                 * @returns updates Extracted table
                 */
                this.allDocsData = response
              })
              this.clearDataSnack = true
              this.activeDocURL = undefined
            } else {
              this.clearDataRes = false
              setTimeout(() => {
                this.clearDataDialog = false
                this.clearDataRes = undefined
              }, 2000)

              api.fetchUpdate().then(response => {
                console.log(response)
                this.updateRes = response
              })
              api.allDocs().then(response => {
                // console.log('All Docs Data: ', response)
                this.allDocsData = response
              })

              this.activeDocURL = undefined
            }

            setTimeout(() => {
              this.clearDataDialog = false
              this.clearDataRes = undefined
            }, 2000)
          })
          .catch(error => {
            console.log(error)
          })
      },

      async validateDoc (guid) {
        /**
         * Validates that specific document
         * @param guid GUID of specific document to be validate
         * @returns validates the document
         */
        const strabeKey = this.extractorRes.fields.find(item => item.key === 'Straße:')
        if (this.extractorRes.NoClassPage) {
          if (strabeKey.value.NoClassVal === '') {
            this.strabeSnack = true
            return
          }
        } else if (!strabeKey.value.BetriVal && !strabeKey.value.druckVal) {
          this.strabeSnack = true
          return
        }

        const reqKeys = this.extractorRes.fields.filter(item => item.key === 'Ort:' || item.key === 'Ortsteil:')
        // here we check if any exists then this will be false
        let getOut = true
        reqKeys.forEach(validationItem => {
          if (validationItem.value.BetriVal || validationItem.value.druckVal || validationItem.value.NoClassVal) {
            getOut = false
          }
        })

        if (getOut) {
          this.reqKeysSnack = true
          return
        }

        // start loader animation
        this.validateLoad = true

        await api.postValidation(guid, this.extractorRes.fields, this.chipsUpdateArray, this.activeDocName, this.extractorRes.badExtractions)
          .then(response => {
            /**
             * call postValidation function in api.js
             * @param guid GUID to get that specific document
             * @param extractorRes.field contains updated values of extracted field
             * @returns updates values on server against that specific document
             */
            console.log(response)
          })
          .catch(err => {
            this.validateLoad = false
            this.genericError = true
            throw err
          })
        // send request to validate document
        api.validateDoc(guid).then(response => {
          /**
           * call validateDoc in api.js
           * @returns validates the document so it can't be change
           */
          api.allDocs().then(response => {
            this.allDocsData = response
          })
            .catch(error => {
              console.error(error)
            })

          this.validatedSnack = true
          this.currValidated = true
          this.validateLoad = false

          this.setDocName()

          setTimeout(() => {
            this.goToNextDoc()
          }, 1500)
        })
          .catch(error => {
            console.error(error)
          })
      },

      downloadPDF () {
        /**
         * downloads the PDF
         */
        api.downloadFile(this.activeDocURL)
          .then(response => {
            /**
             * call downloadFile function in api.js
             * @param activeDocURL contains that documents URL
             * @returns downloads the PDF in user's system
             */
            const url = window.URL
              .createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', this.DocValidName)
            document.body.appendChild(link)
            link.click()
          })
          .catch(error => {
            console.log(error)
          })
      },

      goToPrevDoc () {
        /**
         * go back to previous document on clicking previous button
         */
        let tItems = [];
        if(this.activeTab == 'tab-1') {
          tItems = this.allDocsData;
        }
        else {
          tItems = this.failedDocsData;
        }
        this.prevDocBtnLoader = true
        if (this.currentIndex === 0 && this.pagination !== 1) {
          this.pagination -= 1
          this.currentIndex = this.itemsPerPage - 1
          setTimeout(() => {
            const prevDoc = tItems[this.currentIndex]
            this.loadGUID(prevDoc.guid, prevDoc.validated, prevDoc.fileName, prevDoc.id, this.currentIndex)
          }, 1000)
        } else {
          this.currentIndex -= 1
          const prevDoc = tItems[this.currentIndex]
          this.loadGUID(prevDoc.guid, prevDoc.validated, prevDoc.fileName, prevDoc.id, this.currentIndex)
        }

        // const prevDoc = this.allDocsData.find(doc => doc.id === this.currDocID - 1)
      },

      goToNextDoc () {
        /**
         * go to next document on clicking next button
         */
        let tItems = [];
        if(this.activeTab == 'tab-1') {
          tItems = this.allDocsData;
        }
        else {
          tItems = this.failedDocsData;
        }
        this.nextDocBtnLoader = true
        if (this.currentIndex === this.itemsPerPage - 1 && this.pagination !== this.totalPages) {
          this.pagination += 1
          this.currentIndex = 0
          setTimeout(() => {
            const nextDoc = tItems[this.currentIndex]
            this.loadGUID(nextDoc.guid, nextDoc.validated, nextDoc.fileName, nextDoc.id, this.currentIndex)
          }, 1000)
        } else {
          this.currentIndex += 1
          const nextDoc = tItems[this.currentIndex]
          this.loadGUID(nextDoc.guid, nextDoc.validated, nextDoc.fileName, nextDoc.id, this.currentIndex)
        }
        // const nextDoc = this.allDocsData.find(doc => doc.id === this.currDocID + 1)
      },

      async loadValidKeys (selVal) {
        /**
         * Load key values against selected 'Equipment-Nr'
         * @param selVal contains selected 'Equipment-Nr'
         * @returns key values
         */

        this.cityDataLoader = true
        try {
          let selObj = ''
          await api.loadValidEquip(selVal)
            .then(response => {
              selObj = response.data[0]
            })
            .catch(error => {
              console.log(error)
            })
          console.log(selObj)
          if (!selObj) {
            return
          }
          // assuming first key is equip number
          // TODO: make this a strict condition, match with available equip num
          if (this.extractorRes.BetriPage) {
            if (this.extractorRes.fields.find(field => field.id === 'anlagenakte')) {
              this.extractorRes.fields.find(field => field.id === 'anlagenakte')
                .value.BetriVal = selObj['Equipmentnummer (SAP)']
            }

            if (this.extractorRes.fields.find(field => field.id === 'equipNum')) {
              this.extractorRes.fields.find(field => field.id === 'equipNum')
                .value.BetriVal = selObj['Equipmentnummer (SAP)']
            }

            this.extractorRes.fields.find(field => field.id === 'hausNum')
              .value.BetriVal = selObj.Hausnummer
            this.extractorRes.fields.find(field => field.id === 'ort')
              .value.BetriVal = selObj.Ort
            this.extractorRes.fields.find(field => field.id === 'ortsteil')
              .value.BetriVal = selObj.Ortsteil
            this.extractorRes.fields.find(field => field.id === 'strabe')
              .value.BetriVal = selObj.Straße
            this.extractorRes.featureInstanz.BetriVal = selObj['Feature-Instanz']
            this.extractorRes.postleizahl.BetriVal = selObj.Postleitzahl
          }

          // changing for druck now
          if (this.extractorRes.druckPage) {
            if (this.extractorRes.fields.find(field => field.id === 'anlagenakte')) {
              this.extractorRes.fields.find(field => field.id === 'anlagenakte')
                .value.druckVal = selObj['Equipmentnummer (SAP)']
            }

            if (this.extractorRes.fields.find(field => field.id === 'equipNum')) {
              this.extractorRes.fields.find(field => field.id === 'equipNum')
                .value.druckVal = selObj['Equipmentnummer (SAP)']
            }

            this.extractorRes.fields.find(field => field.id === 'hausNum')
              .value.druckVal = selObj.Hausnummer
            this.extractorRes.fields.find(field => field.id === 'ort')
              .value.druckVal = selObj.Ort
            this.extractorRes.fields.find(field => field.id === 'ortsteil')
              .value.druckVal = selObj.Ortsteil
            this.extractorRes.fields.find(field => field.id === 'strabe')
              .value.druckVal = selObj.Straße
            this.extractorRes.featureInstanz.druckVal = selObj['Feature-Instanz']
            this.extractorRes.postleizahl.druckVal = selObj.Postleitzahl
          }

          // changing for NoClass now
          if (this.extractorRes.NoClassPage) {
            this.extractorRes.fields[0]
              .value.NoClassVal = selObj['Equipmentnummer (SAP)']
            this.extractorRes.fields.find(field => field.id === 'hausNum')
              .value.NoClassVal = selObj.Hausnummer
            this.extractorRes.fields.find(field => field.id === 'ort')
              .value.NoClassVal = selObj.Ort
            this.extractorRes.fields.find(field => field.id === 'ortsteil')
              .value.NoClassVal = selObj.Ortsteil
            this.extractorRes.fields.find(field => field.id === 'strabe')
              .value.NoClassVal = selObj.Straße
            this.extractorRes.featureInstanz.NoClassVal = selObj['Feature-Instanz']
            this.extractorRes.postleizahl.NoClassVal = selObj.Postleitzahl
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.cityDataLoader = false
          // call update chips function
          this.loadUpdatedChips(this.activeGUID)
        }
      },

      loadUpdatedChips (guid) {
        this.chipsLoader = true
        api.loadUpdatedChips(guid, this.extractorRes.fields)
          .then(async (response) => {
            this.loadGUID(this.tempguid, this.tempvalidated, this.tempfileName, this.tempid, this.tempindex)
            this.chipsLoader = false
            /*if (await (response)) {
              this.getChipsData(response)
              this.chipsLoader = false
            }*/

            /*await api.updateJsonMatching(guid, this.extractorRes.fields)
              .then(response => {
                if (response) {
                  this.getUpdatedFieldValues(response)
                }
              })
              .catch(error => {
                console.log(error)
              })*/
          })
          .catch(error => {
            console.log(error)
            setTimeout(() => {
              this.chipsLoader = false
            }, 10000)
          })
      },

      async downloadValidatedDoc () {
        this.loadValidatedDoc = true

        await api.prepareValidatedDoc()
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          })

        const callAPI = setInterval(() => {
          api.getValidatedDocList()
            .then(response => {
              if (response && (response[response.length - 1].status === 'Success' || response[response.length - 1].status === 'Failed')) {
                this.validatedDocGUID = response[response.length - 1].guid

                if (this.validatedDocGUID) {
                  api.downloadValidatedDoc(this.validatedDocGUID)
                    .then(response => {
                      if (response) {
                        const date = new Date()
                        let DateTime = ''
                        DateTime = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '-' + date.getHours() + '.' + date.getMinutes() + '.zip'

                        const url = window.URL.createObjectURL(new Blob([response]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', DateTime)
                        document.body.appendChild(link)
                        link.click()

                        this.loadValidatedDoc = false
                      }
                    })
                    .catch(error => {
                      console.log(error)
                    })
                } else if (!this.validatedDocGUID) {
                  this.downloadValidatedSnack = true
                  this.loadValidatedDoc = false
                }
                clearInterval(callAPI)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }, 2000)
      },

    },
  }
</script>

<style lang="scss">

.v-tabs-items{
  background-color: transparent !important;
  margin-top: -10px;
}

.v-text-field--outlined.add-custom-color-grey fieldset {
  border: 2px solid rgba(124, 124, 124, 0.6) !important;
}
.v-text-field--outlined.add-custom-color-green fieldset {
  border: 2px solid rgba(77, 175, 74, 1) !important;
}
.v-text-field--outlined.add-custom-color-red fieldset {
  border: 2px solid rgba(228, 26, 28, 1) !important;
}
.v-text-field--outlined.add-custom-color-blue fieldset {
  border: 2px solid rgba(55, 126, 184, 1) !important;
}

.v-badge__badge .v-icon{
  font-size: 12px !important;
}

@media (min-width: 1300px){
  .validate-value{
    max-width: 100px !important;
  }
}
@media (min-width: 1400px){
  .validate-value{
    max-width: 110px !important;
  }
}
@media (min-width: 1600px){
  .validate-value{
    max-width: 130px !important;
  }
}
@media (min-width: 1700px){
  .validate-value{
    max-width: 140px !important;
  }
}
@media (min-width: 1800px){
  .validate-value{
    max-width: 160px !important;
  }
}
@media (min-width: 1900px){
  .validate-value{
    max-width: 180px !important;
  }
}
@media (min-width: 2000px){
  .validate-value{
    max-width: 200px !important;
  }
}

</style>
