<template>
  <v-row
    id="statsRow"
    justify="center"
    class="mb-md-4"
  >
    <v-col
      cols="12"
      sm="2"
    >
      <base-material-stats-card
        color="green dareken-2"
        icon="mdi-cloud-upload-outline"
        :small-value="String(updateRes.Uploaded)"
        sub-icon="mdi-file-upload-outline"
        sub-text="Documents Uploaded"
        :loader="fileUploadLoading"
      />
    </v-col>

    <v-col
      cols="12"
      sm="2"
    >
      <base-material-stats-card
        color="cyan lighten-2"
        icon="mdi-ocr"
        :small-value="String(updateRes.OCRed)"
        sub-icon="mdi-smart-card-reader-outline"
        sub-text="Documents OCRed"
        :loader="ocrLoading"
      />
    </v-col>

    <v-col
      cols="12"
      sm="2"
    >
      <base-material-stats-card
        color="blue darken-1"
        icon="mdi-target"
        :small-value="String(updateRes.Identified)"
        sub-icon="mdi-magnify-scan"
        sub-text="Documents Identified"
        :loader="identifiedLoading"
      />
    </v-col>

    <v-col
      cols="12"
      sm="2"
    >
      <base-material-stats-card
        color="teal"
        icon="mdi-file-document-multiple"
        :small-value="String(updateRes.Extracted)"
        sub-icon="mdi-file-document-multiple-outline"
        sub-text="Documents Extracted"
        :loader="extractedLoading"
      />
    </v-col>

    <v-col
      cols="12"
      sm="2"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <base-material-stats-card
          :elevation="hover ? 16 : 2"
          color="red darken-2"
          icon="mdi-alert-box-outline"
          :small-value="String(updateRes.Failed)"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="Documents Failed"
          style="cursor: pointer"
          :loader="failedLoading"
          @click.native="changeTab"
        />
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Stats',

    props: {
      updateRes: {
        type: Object,
        default () {},
      },
      fileUploadLoading: {
        type: Boolean,
        default () {},
      },
      ocrLoading: {
        type: Boolean,
        default () {},
      },
      identifiedLoading: {
        type: Boolean,
        default () {},
      },
      extractedLoading: {
        type: Boolean,
        default () {},
      },
      failedLoading: {
        type: Boolean,
        default () {},
      },
    },

    methods: {
      changeTab () {
        this.$emit('changeTab')
      },
    },
  }
</script>
